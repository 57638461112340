import { Component, Input, LOCALE_ID, OnInit } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";
import { UserList } from "app/shared/models/UserList";
import { CUSTOM_DATE_FORMATS } from "../../../../../../constants";
import localeDe from "@angular/common/locales/de";

@Component({
  selector: "customers-preview-table",
  templateUrl: "./customers-preview-table.component.html",
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        return translate.currentLang;
      },
      deps: [TranslateService],
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  styleUrls: ["./customers-preview-table.component.scss"],
})
export class CustomersPreviewTableComponent implements OnInit {
  @Input() customers: UserList;
  constructor(private router: Router, private translate: TranslateService) {}

  ngOnInit() {
    registerLocaleData(localeDe, "de");
  }

  onActivate($event) {
    if ($event.type == "click") {
      this.router.navigate(["/customers/view/" + $event.row.Id]);
    }
  }

  getRowClass(row) {
    return {
      "new-entry": row.NewEntry,
    };
  }
}
