import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ExternalSystem } from "app/shared/models/ExternalSystem";

@Component({
  selector: "app-active-external-system-card",
  templateUrl: "./active-external-system-card.component.html",
  styleUrls: ["./active-external-system-card.component.scss"],
})
export class ActiveExternalSystemCardComponent implements OnInit {
  @Output() onClickExternalSystemSettings: EventEmitter<any> =
    new EventEmitter();
  @Input() externalSystem: ExternalSystem;
  constructor() {}

  ngOnInit() {}
}
