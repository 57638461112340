import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "content-box",
  templateUrl: "./content-box.component.html",
  styleUrls: ["./content-box.component.scss"],
})
export class ContentBoxComponent implements OnInit {
  @Input() border?: number = 1;
  @Input() mb?: number = 4;
  @Input() background?: string = "background-white";
  constructor() {}

  ngOnInit() {}
}
