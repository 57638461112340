import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CUSTOM_DATE_FORMATS } from "../../../../../../constants";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";
import { ValidationService } from "app/shared/services/validation.service";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/others/crud.service";

@Component({
  selector: "create-payment-form",
  templateUrl: "./create-payment-form.component.html",
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  styleUrls: ["./create-payment-form.component.scss"],
})
export class CreatePaymentFormComponent implements OnInit {
  @Output() afterSave: EventEmitter<any> = new EventEmitter(true);
  @ViewChild("form") form: NgForm;
  public paymentForm: FormGroup;
  public errors = {};
  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private datepipe: DatePipe,
    private loader: AppLoaderService,
    private crudService: CrudService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    const today = new Date();
    const endDate = new Date(today.setMonth(today.getMonth() + 1));

    this.paymentForm = this.fb.group(
      {
        userName: ["", Validators.required],
        newBudget: [0, Validators.required],
        totalBudget: [0, Validators.required],
        startDate: [new Date()],
        endDate: [endDate],
        comment: [""],
        considerMinBudget: [true],
      },
      {
        validator: [
          ValidationService.startDateGreaterThanEndDate("startDate", "endDate"),
        ],
      }
    );
  }

  validateErrors() {
    this.errors = this.getValidationErrors();
  }

  considerMinBudgetToggleChanged($event) {
    this.paymentForm.controls["highlightPromo"].setValue($event.checked);
  }

  getValidationErrors(): Object {
    const errors = {};

    if (this.paymentForm.hasError("startDateLessThanEndDate")) {
      this.paymentForm.controls["endDate"].setErrors({
        startDateLessThanEndDate: true,
      });
    }

    // FormGroup validation errors
    Object.keys(this.paymentForm.controls).forEach((key) => {
      const control = this.paymentForm.get(key);
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  async onSubmit() {
    for (var i in this.paymentForm.controls) {
      this.paymentForm.controls[i].markAsTouched();
    }

    const startDate = this.paymentForm.get("startDate");
    const endDate = this.paymentForm.get("endDate");

    if (this.paymentForm.invalid) {
      this.errors = this.getValidationErrors();
      this.errors["global"] = this.translate.instant(
        "createPaymentFormFailure"
      );

      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      return;
    }

    if (startDate.value != "") {
      this.paymentForm
        .get("startDate")
        .setValue(this.datepipe.transform(startDate.value, "yyyy-MM-dd"));
    }

    if (endDate.value != "") {
      this.paymentForm
        .get("endDate")
        .setValue(this.datepipe.transform(endDate.value, "yyyy-MM-dd"));
    }

    const value = this.paymentForm.getRawValue();

    const request = this.crudService.setNewBudget(
      value.userName,
      value.newBudget,
      value.totalBudget,
      value.startDate,
      value.endDate,
      value.comment,
      value.considerMinBudget
    );

    this.loader.open().subscribe(() => {
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
    });

    let result = null;

    result = await request.toPromise().catch((e) => {
      this.errors["global"] = e.error;
    });

    this.loader.close();

    if (result) {
      this.afterSave.emit({ result: { result } });
    }
  }
}
