import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { StoreAddressModel } from "app/shared/models/StoreAddress";
import { SupportedCountry } from "app/shared/models/SupportedCountry";
import { User } from "app/shared/models/User";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/others/crud.service";
import { environment } from "environments/environment";
import { Global } from "global";

@Component({
  selector: "create-store-form",
  templateUrl: "./create-store-form.component.html",
  styleUrls: ["./create-store-form.component.scss"],
})
export class CreateStoreFormComponent implements OnInit {
  @Output() afterSave: EventEmitter<any> = new EventEmitter(true);
  @ViewChild("form") form: NgForm;
  @Input() data: any;
  @Input() user: User;
  @Input() isLoadingUser?: boolean = false;
  @Input() readonly?: boolean = false;
  public storeForm: FormGroup;
  public errors: any;
  public vStoreList: any;
  public isLoading: boolean;
  public countries: SupportedCountry[];
  public selectedGroup: any;
  public defaultStoreGroup: any;
  public logoFile: any;
  public logoDemoImage: any;
  public backgroundImageFile: any;
  public backgroundImageDemoImage: any;
  public showRadiusMap: boolean;
  public addressChanged: boolean;
  public storeAddress: StoreAddressModel;
  public isLoadingStoreCoordinates: boolean;
  public showStoreCoordinatesError: boolean;
  readonly LOGO_IMAGE_UPLOAD_INDEX = 0;
  readonly BACKGROUND_IMAGE_UPLOAD_INDEX = 1;

  constructor(
    private fb: FormBuilder,
    private crudService: CrudService,
    private snack: MatSnackBar,
    private translate: TranslateService,
    private loader: AppLoaderService
  ) {
    this.errors = {};
    this.showRadiusMap = false;
    this.addressChanged = false;
    this.logoDemoImage = null;
    this.backgroundImageDemoImage = null;
    this.isLoadingStoreCoordinates = false;
    this.showStoreCoordinatesError = false;
    this.isLoading = false;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.isLoadingUser.currentValue) {
      this.init();
    }
  }

  async init() {
    this.isLoading = true;

    const items = this.data.payload;

    this.buildForm(items);
    this.countries = await this.getCountries();
    this.vStoreList = await this.getStores();

    this.setInitCountry();
    this.setStoreData();
    this.setStoreCoordinates(items);
    this.setImages(items);

    this.isLoading = false;
  }

  buildForm(items) {
    this.storeForm = this.fb.group({
      storeId: [items.storeId || null],
      name: [items.name || "", Validators.required],
      emailContact: [items.emailContact || ""],
      description: [items.description || ""],
      parentStoreId: [
        items.parentStoreId === this.user.StoreId
          ? null
          : items.parentStoreId || null,
        Validators.required,
      ],
      hasGroup: [false],
      imageIndex: [items.imageIndex || null],
      backgroundImageIndex: [items.backgroundImageIndex || null],
      address: this.fb.group({
        zip_code: [
          items.address ? items.address.zip_code : "" || "",
          [Validators.required],
        ],
        address: [
          items.address ? items.address.address : "" || "",
          [Validators.required],
        ],
        city: [
          items.address ? items.address.city : "" || "",
          [Validators.required],
        ],
        country: [
          items.address ? items.address.country : "" || "",
          Validators.required,
        ],
      }),

      radius: [items.radius || 10, Validators.pattern("[0-9]*")],
      storeCode: items.storeCode || null,
      openingHours: items.openingHours || null,
      phoneContact: [items.phoneContact || ""],
    });

    if (this.storeForm.controls["emailContact"].value != "") {
      this.storeForm.controls["emailContact"].setValidators(
        ValidationService.emailValidator
      );
    }

    if (this.readonly) {
      this.storeForm.disable();
    }
  }

  getCountries() {
    return this.crudService
      .getSupportedCountries(this.user.StoreId)
      .toPromise();
  }

  getStores() {
    return this.crudService.getVirtualStores(this.user.StoreId).toPromise();
  }

  getCoordinates() {
    const data = this.getAddressObj();
    this.isLoadingStoreCoordinates = true;
    this.crudService
      .getStoreCoordinatesFromAddress(data, this.user.StoreId)
      .subscribe((res) => {
        this.storeAddress = res;
        this.isLoadingStoreCoordinates = false;
      });
  }

  setInitCountry() {
    if (this.countries) {
      this.storeForm
        .get("address")
        ["controls"]["country"].setValue(this.countries[0].Name);
    }
  }

  setStoreData() {
    if (this.vStoreList && this.vStoreList.length > 0) {
      this.defaultStoreGroup = this.vStoreList[0];
      this.vStoreList.shift();

      if (!this.storeForm.controls["parentStoreId"].value) {
        this.storeForm.controls["parentStoreId"].setValue(
          this.defaultStoreGroup.storeId
        );
      } else {
        this.selectedGroup = this.vStoreList.find(
          (store) =>
            store.storeId === this.storeForm.controls["parentStoreId"].value
        );
      }
    }
  }

  setStoreCoordinates(items) {
    if (items.address && items.address.latitude && items.address.longitude) {
      this.storeAddress = {
        longitude: items.address.longitude,
        latitude: items.address.latitude,
        zip_code: items.address.zip_code,
        address: items.address.address,
        city: items.address.city,
        state: items.address.longstateitude,
        country: items.address.country,
      };

      this.showRadiusMap = true;
    }
  }

  uploadImage(image) {
    if (!image) {
      return;
    }

    const myform = new FormData();
    myform.append("Upload_Image", image);

    return this.crudService.uploadStoreImage(myform).toPromise();
  }

  getAddressObj() {
    const { zip_code, address, city, country } =
      this.storeForm.get("address")["controls"];
    return {
      zip_code: zip_code.value,
      address: address.value,
      city: city.value,
      country: country.value,
    };
  }

  validateAddress() {
    const { zip_code, address, city } =
      this.storeForm.get("address")["controls"];

    return zip_code.value != "" && address.value != "" && city.value != "";
  }

  hasGroupToggleChanged($event) {
    this.storeForm.controls["hasGroup"].setValue($event.checked);
  }

  selectedGroupChanged($event) {
    this.selectedGroup = this.vStoreList.find(
      (store) => store.storeId == $event.value
    );
  }

  onSaveGroup() {
    this.storeForm.controls["parentStoreId"].setValue(
      this.selectedGroup.storeId
    );
  }

  onDeleteGroup() {
    this.storeForm.controls["parentStoreId"].setValue(
      this.defaultStoreGroup.storeId
    );
    this.selectedGroup = this.defaultStoreGroup;
  }

  setImages(items) {
    if (items.imageUrl) {
      this.logoDemoImage = items.imageUrl;
    } else {
      this.setDefaultLogoDemoImage();
    }

    if (items.backgroundImageUrl) {
      this.backgroundImageDemoImage = items.backgroundImageUrl;
    } else {
      this.setDefaultBackgroundDemoImage();
    }
  }

  setDefaultLogoDemoImage() {
    this.logoDemoImage =
      Global.ImageUrl +
      "/api/public/GetStoreImage?id=" +
      this.defaultStoreGroup.storeId; //Default image
  }

  setDefaultBackgroundDemoImage() {
    this.backgroundImageDemoImage =
      Global.ImageUrl +
      "/api/public/GetStoreBackground?id=" +
      this.defaultStoreGroup.storeId; //Default image
  }

  onLogoDroppedEvent($event) {
    const files: File[] = $event;
    this.logoFile = <File>$event[0];
    const reader = new FileReader();

    reader.onload = (_event) => {
      this.logoDemoImage = reader.result;
    };

    reader.readAsDataURL(files[0]);
  }

  onBackgroundImageDroppedEvent($event) {
    const files: File[] = $event;
    this.backgroundImageFile = <File>$event[0];
    const reader = new FileReader();

    reader.onload = (_event) => {
      this.backgroundImageDemoImage = reader.result;
    };

    reader.readAsDataURL(files[0]);
  }

  removeLogo() {
    this.logoFile = null;
    this.logoDemoImage = null;
    this.storeForm.controls["imageIndex"].setValue(null);
  }

  removeBackgroundImage() {
    this.backgroundImageFile = null;
    this.backgroundImageDemoImage = null;
    this.storeForm.controls["backgroundImageIndex"].setValue(null);
  }

  radiusChange($event) {
    this.setRadius($event.value);
  }

  setRadius(value) {
    this.storeForm.controls["radius"].setValue(value);
  }

  onShowRadiusMap() {
    this.addressChanged = false;
    this.showStoreCoordinatesError = !this.validateAddress();

    if (!this.showStoreCoordinatesError) {
      this.getCoordinates();
      this.showRadiusMap = true;
    } else {
      this.snack.open(
        this.translate.instant("PleaseEnterYourFullAddressFirst"),
        "OK",
        {
          duration: 4000,
        }
      );
    }
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.storeForm.controls);
  }

  getValidationErrorsByControls(controls) {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  onKeyUpEmailContact($event) {
    const value = $event.target.value;

    if (value === "") {
      this.storeForm.controls["emailContact"].clearValidators();
    } else {
      this.storeForm.controls["emailContact"].setValidators(
        ValidationService.emailValidator
      );
    }

    this.storeForm.controls["emailContact"].updateValueAndValidity();
  }

  async onSubmit() {
    this.markFormControls(this.storeForm.controls);

    // Form validation
    if (this.storeForm.invalid) {
      this.getValidationErrors();
      this.errors["global"] = this.translate.instant("createPromoFormFailure");

      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      return;
    }

    this.loader.open().subscribe(() => {
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
    });

    // Upload Images
    try {
      if (this.logoFile) {
        const logoFileResponse = await this.uploadImage(this.logoFile);
        this.storeForm.controls["imageIndex"].setValue(logoFileResponse.body);
      }

      if (this.backgroundImageFile) {
        const backgroundFileResponse = await this.uploadImage(
          this.backgroundImageFile
        );
        this.storeForm.controls["backgroundImageIndex"].setValue(
          backgroundFileResponse.body
        );
      }
    } catch (e) {
      this.errors["global"] = e.error;
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      return;
    }

    // Makte request
    const value = this.storeForm.getRawValue();
    const request = this.data.isNew
      ? this.crudService.addStoreِApp(value, this.user.StoreId)
      : this.crudService.updateStore(value, this.user.StoreId);

    let success = true;
    let result = null;

    try {
      result = await request.toPromise();
    } catch (e) {
      this.errors["global"] = e.error.Message;
      success = false;
    } finally {
      this.loader.close();
    }

    if (success) {
      this.afterSave.emit({ result: { success } });
    }
  }
}
