import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

@Pipe({ name: "relativeTime" })
export class RelativeTimePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: Date | string) {
    if (value === null) return "";
    if (!(value instanceof Date)) value = new Date(value);
    moment.locale(this.translate.currentLang);
    return moment(value).fromNow();
  }
}
