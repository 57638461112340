import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { FacebookAdsPreviewComponent } from "app/shared/components/dialogs/ads/facebook-ads-preview/facebook-ads-preview.component";
import { ChannelHistory } from "app/shared/models/ChannelHistory";
import { CrudService } from "app/views/others/crud.service";
import * as moment from "moment";

@Component({
  selector: "activities-timeline",
  templateUrl: "./activities-timeline.component.html",
  styleUrls: ["./activities-timeline.component.scss"],
})
export class ActivitiesTimelineComponent implements OnInit {
  @Input() startDate? = null;
  @Input() storeId: number;
  @Input() endDate? = null;
  @Input() channelId? = null;
  @Input() activity? = null;
  @Input() searchText? = null;
  @Input() usePagination? = false;
  public channelHistories: ChannelHistory[];
  public isLoading: boolean = true;
  public sliderContainerWidth: number = 0;
  public sliderContentWidth: number = 0;
  public sliderItemWidth: number = 0;
  public translateXSlider: number = 0;
  public translateXMax: number = 0;
  public translateXStep: number = 0;
  public selectIndex: number = 0;
  public circleValues: Array<any> = [];
  public selectSteps: number = 0;
  public offset: number = 0;
  public limit: number = 20;
  public count: number = 0;
  public pages: number = 1;
  constructor(
    private crudService: CrudService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  async ngOnChanges(changes: SimpleChanges) {
    this.isLoading = true;
    this.offset = 0;
    const result = await this.getChannelHistory();
    this.channelHistories = result.subChannelStatistics;
    this.count = result.count;
    this.pages = Math.ceil(this.count / this.limit);

    const self = this;
    this.calcTimeDiffrence();
    setTimeout(function () {
      self.circleValues = [];
      self.translateXSlider = 0;
      self.selectIndex = 0;
      self.initSlider();
      self.isLoading = false;
    }, 500);
    this.cdr.detectChanges();
  }

  @HostListener("window:resize", ["$event"])
  onHostResize(event) {
    this.isLoading = true;
    const self = this;
    setTimeout(function () {
      self.circleValues = [];
      self.translateXSlider = 0;
      self.selectIndex = 0;
      self.initSlider();
      self.isLoading = false;
    }, 500);
  }

  initSlider() {
    const container = document.getElementById("slider-container");

    const sliderItems = document.querySelectorAll(".activities-timeline-item");
    const containerWidth = container.clientWidth;
    this.sliderContainerWidth = containerWidth;

    sliderItems.forEach((sliderItem) => {
      (sliderItem as HTMLElement).style.width = `${
        document.body.clientWidth < 595 ? this.sliderContainerWidth : 140
      }px`;
    });

    if (sliderItems.length > 0) {
      this.sliderItemWidth = sliderItems[0].clientWidth;
    }

    const sliderContent = document.getElementById("slider-content");
    this.sliderContentWidth = sliderContent.scrollWidth;

    this.setMaxWidth();
    this.setStepWidth();
    this.setCircleValues();
  }

  setCircleValues() {
    this.selectSteps =
      Math.ceil(this.sliderContentWidth / this.translateXStep) - 1;

    for (let i = 0; i < this.selectSteps; i++) {
      this.circleValues.push([]);
    }
  }

  setMaxWidth() {
    this.translateXMax =
      this.sliderContentWidth - this.sliderContainerWidth < 0
        ? 0
        : this.sliderContentWidth - this.sliderContainerWidth;
  }

  setStepWidth() {
    this.translateXStep =
      this.sliderContainerWidth / 2 < this.sliderItemWidth
        ? this.sliderContainerWidth
        : this.sliderContainerWidth / 2;
  }

  getChannelHistory() {
    return this.crudService
      .getChannelHistory(
        this.storeId,
        this.startDate,
        this.endDate,
        this.channelId,
        this.activity,
        this.offset,
        this.limit,
        this.searchText,
        "CreationDate",
        0
      )
      .toPromise();
  }

  async reloadChannelHistories() {
    if (this.offset >= this.pages - 1) {
      return;
    }

    this.offset++;

    this.isLoading = true;
    const result = await this.getChannelHistory();
    this.channelHistories = this.channelHistories.concat(
      result.subChannelStatistics
    );
    this.count = result.count;
    this.pages = Math.ceil(this.count / this.limit);

    const self = this;
    this.calcTimeDiffrence();
    setTimeout(function () {
      self.initSlider();
      self.isLoading = false;
    }, 500);
  }

  calcTimeDiffrence() {
    for (let i = 0; i < this.channelHistories.length; i++) {
      if (i + 1 === this.channelHistories.length) {
        this.channelHistories[i]["TimeDifference"] = 0;
      } else {
        const creationDate = moment(this.channelHistories[i].CreationDate);
        const nextCreationDate = moment(
          this.channelHistories[i + 1].CreationDate
        );
        let diffWeeks = creationDate.diff(nextCreationDate, "week");

        if (diffWeeks > 10) {
          diffWeeks = 10;
        } else if (diffWeeks === 0) {
          diffWeeks = 1;
        }

        this.channelHistories[i]["TimeDifference"] = diffWeeks;
      }
    }
  }

  toIndex($event) {
    this.selectIndex = $event.index;
    const width = this.translateXStep * -1 * this.selectIndex;

    this.translateXSlider =
      width < this.translateXMax * -1
        ? this.translateXMax * -1
        : this.translateXStep * -1 * this.selectIndex;
  }

  next() {
    if (
      this.translateXSlider - this.translateXStep <=
      this.translateXMax * -1
    ) {
      this.translateXSlider = this.translateXMax * -1;
      this.selectIndex = this.selectSteps - 1;

      if (this.usePagination) {
        this.reloadChannelHistories();
      }
    } else {
      this.translateXSlider -= this.translateXStep;
      this.selectIndex++;
    }
  }

  prev() {
    if (this.translateXSlider + this.translateXStep > 0) {
      this.translateXSlider = 0;
      this.selectIndex = 0;
    } else {
      this.translateXSlider += this.translateXStep;
      this.selectIndex--;
    }
  }

  onOpenPreview(adId: number, storeId: number) {
    this.dialog.open(FacebookAdsPreviewComponent, {
      width: "1000px",
      height: "95vh",
      data: {
        adId,
        storeId,
      },
    });
  }
}
