import { NestedTreeControl } from "@angular/cdk/tree";
import { Component, Input, OnInit } from "@angular/core";
import { MatTreeNestedDataSource } from "@angular/material";
import { StoreNode } from "app/shared/models/StoreNode";

@Component({
  selector: "app-store-tree",
  templateUrl: "./store-tree.component.html",
  styleUrls: ["./store-tree.component.scss"],
})
export class StoreTreeComponent implements OnInit {
  @Input() storeTree: any;

  treeControl = new NestedTreeControl<StoreNode>((node) => node.Children);

  dataSource = new MatTreeNestedDataSource<StoreNode>();

  constructor() {}

  ngOnInit() {
    this.dataSource.data = [this.storeTree];
    this.treeControl.dataNodes = this.dataSource.data;
    this.treeControl.expandAll();
  }

  ngAfterViewInit() {}

  hasChild = (_: number, node: StoreNode) =>
    !!node.Children && node.Children.length > 0;
}
