import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent implements OnInit {
  @Input() cols?: number = 1;
  constructor() {}

  ngOnInit() {}
}
