import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ExternalSystem } from "app/shared/models/ExternalSystem";
import { ExternalSystemUserInformation } from "app/shared/models/ExternalSystemUserInformation";

@Component({
  selector: "settings-external-system-connection",
  templateUrl: "./settings-external-system-connection.component.html",
  styleUrls: ["./settings-external-system-connection.component.scss"],
})
export class SettingsExternalSystemConnectionComponent implements OnInit {
  @Input() externalSystem: ExternalSystem;
  @Input() externalSystemInformation: ExternalSystemUserInformation;
  @Output() removeConnection: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
