import { Component, OnInit, Input } from "@angular/core";
import { TContract } from "app/shared/models/TContract";

@Component({
  selector: "app-popover-tcontract-info",
  templateUrl: "./popover-tcontract-info.component.html",
  styleUrls: ["./popover-tcontract-info.component.scss"],
})
export class PopoverTcontractInfoComponent implements OnInit {
  @Input() show: boolean;
  @Input() tContract: TContract;
  constructor() {}

  ngOnInit() {}
}
