import { Component, Input, OnInit } from "@angular/core";
import { User } from "app/shared/models/User";

@Component({
  selector: "facebook-account-status-card",
  templateUrl: "./facebook-account-status-card.component.html",
  styleUrls: ["./facebook-account-status-card.component.scss"],
})
export class FacebookAccountStatusCardComponent implements OnInit {
  @Input() allFacebookStatus: any;
  @Input() facebookAccount: any;
  @Input() user: User;
  public activeStatus: any;
  constructor() {}

  ngOnInit() {
    this.activeStatus = this.allFacebookStatus.find(
      (status) => status.Id == this.facebookAccount.Status
    );
  }
}
