import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MPGDNCampaign } from "app/shared/models/marketing-portal/MPGDNCampaign";
import { Global } from "global";

@Component({
  selector: "marketing-portal-gdncampaign-overview",
  templateUrl: "./marketing-portal-gdncampaign-overview.component.html",
  styleUrls: ["./marketing-portal-gdncampaign-overview.component.scss"],
})
export class MarketingPortalGDNcampaignOverviewComponent implements OnInit {
  @Input() googleDisplayCampaign: MPGDNCampaign;
  readonly ImageUrl = Global.ImageUrl;
  constructor(private router: Router) {}

  ngOnInit() {}
}
