import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "timeDiff",
})
export class TimeDiffPipe implements PipeTransform {
  transform(start: any, end: any = null): string {
    start = moment(start);
    end = !end ? moment.utc() : moment(end);
    var duration = moment.duration(end.diff(start));

    var years = duration.years();
    var months = duration.months();
    var days = duration.days();
    var hours = duration.hours();
    var minutes = duration.minutes();
    var result = "";

    if (years > 0) {
      result += years + `${years > 1 ? " Jahre" : " Jahr"}`;
      if (months > 0) {
        result += ", " + months + `${months > 1 ? " Monate" : " Monat"}`;
      }
    } else if (months > 0) {
      result += months + `${months > 1 ? " Monate" : " Monat"}`;
      if (days > 0) {
        result += ", " + days + `${days > 1 ? " Tage" : " Tag"}`;
      }
    } else if (days > 0) {
      result += days + `${days > 1 ? " Tage" : " Tag"}`;
      if (hours > 0) {
        result += ", " + hours + `${hours > 1 ? " Stunden" : " Stunde"}`;
      }
    } else {
      result += hours + `${hours > 1 ? " Stunden" : " Stunde"}`;
      if (minutes > 0) {
        result += ", " + minutes + `${minutes > 1 ? " Minuten" : " Minute"}`;
      }
    }

    return result;
  }
}
