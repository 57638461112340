import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import {
  MatTreeModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatBadgeModule,
  MatTabsModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatDatepicker,
  MatDatepickerModule,
  MatChipsModule,
  MatInputModule,
  MatFormFieldModule,
  MatButtonToggleModule,
} from "@angular/material";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./components/header-side/header-side.component";
import { SidebarSideComponent } from "./components/sidebar-side/sidebar-side.component";

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./components/header-top/header-top.component";
import { SidebarTopComponent } from "./components/sidebar-top/sidebar-top.component";

// ONLY FOR DEMO (Removable without changing any layout configuration)
import { CustomizerComponent } from "./components/customizer/customizer.component";

// ALL TIME REQUIRED
import { AdminLayoutComponent } from "./components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./components/layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { AppComfirmComponent } from "./services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "./services/app-loader/app-loader.component";

// DIRECTIVES
import { FontSizeDirective } from "./directives/font-size.directive";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { AppDropdownDirective } from "./directives/dropdown.directive";
import { DropdownAnchorDirective } from "./directives/dropdown-anchor.directive";
import { DropdownLinkDirective } from "./directives/dropdown-link.directive";
import { EgretSideNavToggleDirective } from "./directives/egret-side-nav-toggle.directive";

// PIPES
import { RelativeTimePipe } from "./pipes/relative-time.pipe";
import { ExcerptPipe } from "./pipes/excerpt.pipe";
import { GetValueByKeyPipe } from "./pipes/get-value-by-key.pipe";

// SERVICES
import { ThemeService } from "./services/theme.service";
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from "./services/route-parts.service";
import { AuthGuard } from "./services/auth/auth.guard";
import { AppConfirmService } from "./services/app-confirm/app-confirm.service";
import { AppLoaderService } from "./services/app-loader/app-loader.service";
import { ButtonLoadingComponent } from "./components/button-loading/button-loading.component";
import { SearchModule } from "./search/search.module";
import { ChatService } from "./components/notifications/notifications.service";
import { PageHeaderComponent } from "./components/partials/page-header/page-header.component";
import { ContentBoxComponent } from "./components/content-box/content-box/content-box.component";
import { ContentBoxItemComponent } from "./components/content-box/content-box-item/content-box-item.component";
import { ContentBoxItemLabelComponent } from "./components/content-box/content-box-item-label/content-box-item-label.component";
import { ContentBoxItemValueComponent } from "./components/content-box/content-box-item-value/content-box-item-value.component";
import { ContentBoxLabelValueComponent } from "./components/content-box/content-box-label-value/content-box-label-value.component";
import { ContentBoxTitleComponent } from "./components/content-box/content-box-title/content-box-title.component";
import { ImageUploadIconButtonComponent } from "./components/partials/image-upload-icon-button/image-upload-icon-button.component";
import { UploadImageDialogComponent } from "./components/dialogs/upload-image-dialog/upload-image-dialog.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImageBackgroundRemoverComponent } from "./components/image/image-background-remover/image-background-remover.component";
import { SelectImageFromSelectionComponent } from "./components/select/select-image-from-selection/select-image-from-selection.component";
import { ImageCropperComponent } from "./components/image/image-cropper/image-cropper.component";
import { InlineIconMessageComponent } from "./components/messages/inline-icon-message/inline-icon-message.component";
import { InfoActionMessageComponent } from "./components/messages/info-action-message/info-action-message.component";
import { InfoIconMessageComponent } from "./components/messages/icon-message/info-icon-message/info-icon-message.component";
import { IconMessageComponent } from "./components/messages/icon-message/icon-message.component";
import { CreatePromotionFormComponent } from "./components/forms/promotions/create-promotion-form/create-promotion-form.component";
import { SearchGtinPromotionFormComponent } from "./components/forms/promotions/search-gtin-promotion-form/search-gtin-promotion-form.component";
import { ErrorIconMessageComponent } from "./components/messages/icon-message/error-icon-message/error-icon-message.component";
import { SuccessIconMessageComponent } from "./components/messages/icon-message/success-icon-message/success-icon-message.component";
import { PopoverTcontractInfoComponent } from "./components/notifications/popover/popover-tcontract-info/popover-tcontract-info.component";
import { PopoverPasswordCheckComponent } from "./components/notifications/popover/popover-password-check/popover-password-check.component";
import { PopoverComponent } from "./components/notifications/popover/popover.component";
import { HorizontalDividerComponent } from "./components/partials/divider/horizontal-divider/horizontal-divider.component";
import { VerticalDividerComponent } from "./components/partials/divider/vertical-divider/vertical-divider.component";
import { DragDropUploadComponent } from "./components/drag-drop-upload/drag-drop-upload.component";
import { StoreSelectionTreeComponent } from "./components/trees/store-selection-tree/store-selection-tree.component";
import { StoreTreeComponent } from "./components/trees/store-tree/store-tree.component";
import { PromotionSuccessfullyCreatedDialogComponent } from "./components/dialogs/promotions/promotion-successfully-created-dialog/promotion-successfully-created-dialog.component";
import { AnimationCheckmarkCircleComponent } from "./components/partials/animation-checkmark-circle/animation-checkmark-circle.component";
import { BasicChannelsGridComponent } from "./components/grids/basic-channels-grid/basic-channels-grid.component";
import { BasicChannelCardComponent } from "./components/cards/basic-channel-card/basic-channel-card.component";
import { PromotionSuccessfullyCreatedComponent } from "app/views/customers/customer-view/customer-view-products/promotion-successfully-created/promotion-successfully-created.component";
import { PromotionPreviewDialogComponent } from "./components/dialogs/promotions/promotion-preview-dialog/promotion-preview-dialog.component";
import { FacebookAdsPreviewComponent } from "./components/dialogs/ads/facebook-ads-preview/facebook-ads-preview.component";
import { GooglePreviewComponent } from "./components/previews/google-preview/google-preview.component";
import { YoutubePreviewComponent } from "./components/previews/youtube-preview/youtube-preview.component";
import { GoogleLocalInventoryPreviewComponent } from "./components/previews/google-local-inventory-preview/google-local-inventory-preview.component";
import { InstagramPreviewComponent } from "./components/previews/instagram-preview/instagram-preview.component";
import { FacebookPreviewComponent } from "./components/previews/facebook-preview/facebook-preview.component";
import { SelectCirclesComponent } from "./components/select-circles/select-circles.component";
import { StoresMapComponent } from "./components/maps/stores-map/stores-map.component";
import { CreateStoreFormComponent } from "./components/forms/stores/create-store-form/create-store-form.component";
import { SuccessActionIconMessageComponent } from "./components/messages/success-action-icon-message/success-action-icon-message.component";
import { RadiusMapComponent } from "./components/maps/radius-map/radius-map.component";
import { StepSliderComponent } from "./components/select/step-slider/step-slider.component";
import { PillComponent } from "./components/partials/pill/pill.component";
import { AddActionBoxComponent } from "./components/select/item-action-box/add-action-box/add-action-box.component";
import { SelectActionBoxComponent } from "./components/select/item-action-box/select-action-box/select-action-box.component";
import { ViewActionBoxComponent } from "./components/select/item-action-box/view-action-box/view-action-box.component";
import { SelectItemActionBoxComponent } from "./components/select/select-item-action-box/select-item-action-box.component";
import { CreateGroupFormComponent } from "./components/forms/groups/create-group-form/create-group-form.component";
import { AddNewActionMessageComponent } from "./components/messages/add-new-action-message/add-new-action-message.component";
import { ActiveExternalSystemsGridComponent } from "./components/grids/active-external-systems-grid/active-external-systems-grid.component";
import { ActiveExternalSystemCardComponent } from "./components/cards/active-external-system-card/active-external-system-card.component";
import { InlineActionIconMessageComponent } from "./components/messages/inline-action-icon-message/inline-action-icon-message.component";
import { ExternalSystemCardComponent } from "./components/cards/external-system-card/external-system-card.component";
import { ExternalSystemsGridComponent } from "./components/grids/external-systems-grid/external-systems-grid.component";
import { ExternalSystemsSupportedImportFormatsComponent } from "./components/partials/external-systems-supported-import-formats/external-systems-supported-import-formats.component";
import { AddExternalSystemFormComponent } from "./components/forms/external-systems/add-external-system-form/add-external-system-form.component";
import { SuccessAnimationActionMessageComponent } from "./components/messages/success-animation-action-message/success-animation-action-message.component";
import { ErrorActionMessageComponent } from "./components/messages/error-action-message/error-action-message.component";
import { AddExternalSystemDefaultComponent } from "./components/partials/add-external-system-default/add-external-system-default.component";
import { SettingsExternalSystemCategoriesComponent } from "app/views/customers/customer-view/customer-view-external-systems/customer-view-external-systems-settings/settings-external-system-categories/settings-external-system-categories.component";
import { SettingsExternalSystemConnectionComponent } from "app/views/customers/customer-view/customer-view-external-systems/customer-view-external-systems-settings/settings-external-system-connection/settings-external-system-connection.component";
import { SettingsExternalSystemInformationComponent } from "app/views/customers/customer-view/customer-view-external-systems/customer-view-external-systems-settings/settings-external-system-information/settings-external-system-information.component";
import { SettingsExternalSystemTagsComponent } from "app/views/customers/customer-view/customer-view-external-systems/customer-view-external-systems-settings/settings-external-system-tags/settings-external-system-tags.component";
import { CustomActionIconMessageComponent } from "./components/messages/custom-action-icon-message/custom-action-icon-message.component";
import { GoogleAccountStatusCardComponent } from "./components/cards/google-account-status-card/google-account-status-card.component";
import { FacebookAccountStatusCardComponent } from "./components/cards/facebook-account-status-card/facebook-account-status-card.component";
import { CircleProgressLineComponent } from "./components/partials/circle-progress-line/circle-progress-line.component";
import { FacebookPageDetailCardComponent } from "./components/cards/facebook-page-detail-card/facebook-page-detail-card.component";
import { GridComponent } from "./components/grids/grid/grid.component";
import { SubscriptionsDetailTableComponent } from "./components/tables/subscriptions-detail-table/subscriptions-detail-table.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { TimeDiffPipe } from "./pipes/time-diff.pipe";
import { CreatePaymentFormComponent } from "./components/forms/payments/create-payment-form/create-payment-form.component";
import { CustomersPreviewTableComponent } from "./components/tables/customers/customers-preview-table/customers-preview-table.component";
import { SubscriptionsPreviewTableComponent } from "./components/tables/subscriptions/subscriptions-preview-table/subscriptions-preview-table.component";
import { PaymentsPreviewTableComponent } from "./components/tables/payments/payments-preview-table/payments-preview-table.component";
import { TrendArrowComponent } from "./components/partials/trend-arrow/trend-arrow.component";
import { SelectMenu } from "./models/SelectMenu";
import { SelectMenuItemComponent } from "./components/select/select-menu-item/select-menu-item.component";
import { SelectMenuComponent } from "./components/select/select-menu/select-menu.component";
import { SelectMultipleMenuComponent } from "./components/select/select-multiple-menu/select-multiple-menu.component";
import { NoDataMessageComponent } from "./components/messages/no-data-message/no-data-message.component";
import { TrendValueComponent } from "./components/partials/trend-value/trend-value.component";
import { ImpressionsDifferenceComponent } from "./components/partials/impressions-difference/impressions-difference.component";
import { StoreDetailCardComponent } from "./components/cards/store-detail-card/store-detail-card.component";
import { StoreLocationSelectListComponent } from "./components/lists/store-location-select-list/store-location-select-list.component";
import { ZipcodeImpressionsSelectListComponent } from "./components/lists/zipcode-impressions-select-list/zipcode-impressions-select-list.component";
import { HeatMapSettingsComponent } from "./components/partials/heat-map-settings/heat-map-settings.component";
import { ColorBoxComponent } from "./components/partials/color-box/color-box.component";
import { CorporateColorSelectorDialogComponent } from "./components/dialogs/corporate-color-selector-dialog/corporate-color-selector-dialog.component";
import { GetTemplateScreenshotByIdPipe } from "./pipes/templates/get-template-screenshot-by-id.pipe";
import { GetTemplateNameByIdPipe } from "./pipes/templates/get-template-name-by-id.pipe";
import { GetStoreNameByIdPipe } from "./pipes/stores/get-store-name-by-id.pipe";
import { VideoTemplateSelectorDialogComponent } from "./components/dialogs/video-template-selector-dialog/video-template-selector-dialog.component";
import { SelectedVideoTemplateComponent } from "./components/partials/selected-video-template/selected-video-template.component";
import { VideoTemplateGridComponent } from "./components/grids/video-template-grid/video-template-grid.component";
import { VideoTemplateCardComponent } from "./components/cards/video-template-card/video-template-card.component";
import { NoImagesUploadedConfirmationDialogComponent } from "./components/dialogs/no-images-uploaded-confirmation-dialog/no-images-uploaded-confirmation-dialog.component";
import { ActivitiesTimelineComponent } from "app/views/statistics/activities-timeline/activities-timeline.component";
import { CampaignsDetailTableComponent } from "./components/tables/campaigns/campaigns-detail-table/campaigns-detail-table.component";
import { CampaignDetailsComponent } from "./components/partials/campaign-details/campaign-details.component";
import { SmAdsCampaignDetailsComponent } from "./components/partials/campaign-details/sm-ads-campaign-details/sm-ads-campaign-details.component";
import { MarketingPortalSmcampaignOverviewComponent } from "./components/partials/campaign-details/marketing-portal-smcampaign-overview/marketing-portal-smcampaign-overview.component";
import { CampaignCustomerActionCardComponent } from "./components/cards/campaign-customer-action-card/campaign-customer-action-card.component";
import { SmartphoneIphoneXComponent } from "./components/previews/smartphone-iphone-x/smartphone-iphone-x.component";
import { CampaignFacebookPreviewComponent } from "./components/previews/campaign-facebook-preview/campaign-facebook-preview.component";
import { CampaignGdnAdsPreviewComponent } from "./components/previews/campaign-gdn-ads-preview/campaign-gdn-ads-preview.component";
import { LocaleDatePipe } from "./pipes/locale-date.pipe";
import { GoogleCampaignDetailsComponent } from "./components/partials/campaign-details/google-campaign-details/google-campaign-details.component";
import { MarketingPortalGDNcampaignOverviewComponent } from "./components/partials/campaign-details/marketing-portal-gdncampaign-overview/marketing-portal-gdncampaign-overview.component";

/* 
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  CustomizerComponent,
  ButtonLoadingComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  RelativeTimePipe,
  LocaleDatePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  TimeDiffPipe,
  GetTemplateNameByIdPipe,
  GetTemplateScreenshotByIdPipe,
  GetStoreNameByIdPipe,
  PageHeaderComponent,
  ContentBoxComponent,
  ContentBoxItemComponent,
  ContentBoxItemLabelComponent,
  ContentBoxItemValueComponent,
  ContentBoxLabelValueComponent,
  ContentBoxTitleComponent,
  ImageUploadIconButtonComponent,
  UploadImageDialogComponent,
  ImageCropperComponent,
  ImageBackgroundRemoverComponent,
  SelectImageFromSelectionComponent,
  InlineIconMessageComponent,
  InfoActionMessageComponent,
  InfoIconMessageComponent,
  IconMessageComponent,
  CreatePromotionFormComponent,
  SearchGtinPromotionFormComponent,
  ErrorIconMessageComponent,
  SuccessIconMessageComponent,
  PopoverTcontractInfoComponent,
  PopoverPasswordCheckComponent,
  PopoverComponent,
  HorizontalDividerComponent,
  VerticalDividerComponent,
  DragDropUploadComponent,
  StoreSelectionTreeComponent,
  StoreTreeComponent,
  PromotionSuccessfullyCreatedDialogComponent,
  AnimationCheckmarkCircleComponent,
  BasicChannelsGridComponent,
  BasicChannelCardComponent,
  PromotionSuccessfullyCreatedComponent,
  PromotionPreviewDialogComponent,
  FacebookAdsPreviewComponent,
  GooglePreviewComponent,
  YoutubePreviewComponent,
  GoogleLocalInventoryPreviewComponent,
  InstagramPreviewComponent,
  FacebookPreviewComponent,
  SelectCirclesComponent,
  StoresMapComponent,
  CreateStoreFormComponent,
  SuccessActionIconMessageComponent,
  RadiusMapComponent,
  StepSliderComponent,
  PillComponent,
  AddActionBoxComponent,
  SelectActionBoxComponent,
  ViewActionBoxComponent,
  SelectItemActionBoxComponent,
  CreateGroupFormComponent,
  AddNewActionMessageComponent,
  ActiveExternalSystemsGridComponent,
  ActiveExternalSystemCardComponent,
  InlineActionIconMessageComponent,
  ExternalSystemCardComponent,
  ExternalSystemsGridComponent,
  ExternalSystemsSupportedImportFormatsComponent,
  AddExternalSystemFormComponent,
  SuccessAnimationActionMessageComponent,
  ErrorActionMessageComponent,
  AddExternalSystemDefaultComponent,
  SettingsExternalSystemCategoriesComponent,
  SettingsExternalSystemConnectionComponent,
  SettingsExternalSystemInformationComponent,
  SettingsExternalSystemTagsComponent,
  CustomActionIconMessageComponent,
  GoogleAccountStatusCardComponent,
  FacebookAccountStatusCardComponent,
  CircleProgressLineComponent,
  FacebookPageDetailCardComponent,
  GridComponent,
  SubscriptionsDetailTableComponent,
  CreatePaymentFormComponent,
  CustomersPreviewTableComponent,
  SubscriptionsPreviewTableComponent,
  PaymentsPreviewTableComponent,
  TrendArrowComponent,
  SelectMenuComponent,
  SelectMenuItemComponent,
  SelectMultipleMenuComponent,
  NoDataMessageComponent,
  TrendValueComponent,
  ImpressionsDifferenceComponent,
  StoreDetailCardComponent,
  StoreLocationSelectListComponent,
  ZipcodeImpressionsSelectListComponent,
  HeatMapSettingsComponent,
  ColorBoxComponent,
  CorporateColorSelectorDialogComponent,
  VideoTemplateSelectorDialogComponent,
  SelectedVideoTemplateComponent,
  VideoTemplateGridComponent,
  VideoTemplateCardComponent,
  NoImagesUploadedConfirmationDialogComponent,
  ActivitiesTimelineComponent,
  CampaignsDetailTableComponent,
  CampaignDetailsComponent,
  SmAdsCampaignDetailsComponent,
  MarketingPortalSmcampaignOverviewComponent,
  CampaignCustomerActionCardComponent,
  SmartphoneIphoneXComponent,
  CampaignFacebookPreviewComponent,
  CampaignGdnAdsPreviewComponent,
  GoogleCampaignDetailsComponent,
  MarketingPortalGDNcampaignOverviewComponent,
];

@NgModule({
  imports: [
    MatTreeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatDatepickerModule,
    MatSelectModule,
    MatMenuModule,
    MatTabsModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
    SearchModule,
    PerfectScrollbarModule,
    MatSliderModule,
    MatSlideToggleModule,
    ImageCropperModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatInputModule,
    MatFormFieldModule,
    NgxDatatableModule,
    MatButtonToggleModule,
  ],
  entryComponents: [
    AppComfirmComponent,
    AppLoaderComponent,
    UploadImageDialogComponent,
    PromotionSuccessfullyCreatedDialogComponent,
    PromotionPreviewDialogComponent,
    CorporateColorSelectorDialogComponent,
    VideoTemplateSelectorDialogComponent,
    NoImagesUploadedConfirmationDialogComponent,
    FacebookAdsPreviewComponent,
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    AppConfirmService,
    AppLoaderService,
    ChatService,
    // LandingPageService
  ],
  declarations: classesToInclude,
  exports: classesToInclude,
})
export class SharedModule {}
