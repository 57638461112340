import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MatAutocompleteSelectedEvent,
  MatDialog,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

import { PromotionPreviewDialogComponent } from "app/shared/components/dialogs/promotions/promotion-preview-dialog/promotion-preview-dialog.component";
import { Promo } from "app/shared/promoStruct";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CurrencyService } from "app/shared/services/currency.service";
import { ValidationService } from "app/shared/services/validation.service";
import {
  USER_TYPE_ID_SERVICE_PROVIDER,
  AGE_GROUP_ID,
  GENDER_ID,
  COLOR_ID,
  UNIT_ID,
  USER_TYPE_ID_MERCHANT,
  USER_TYPE_ID_GASTRO,
  CATE_ID,
  SUB_CAT_ID,
  CUSTOM_DATE_FORMATS,
} from "../../../../../../constants";

import { forkJoin } from "rxjs";

import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";

import { RelativeTimePipe } from "app/shared/pipes/relative-time.pipe";

import { NoImagesUploadedConfirmationDialogComponent } from "app/shared/components/dialogs/no-images-uploaded-confirmation-dialog/no-images-uploaded-confirmation-dialog.component";
import { CustomFileUploader } from "app/shared/custom_file_uploader";
import { DemoDisplay } from "app/shared/models/DemoDisplay";
import { TContract } from "app/shared/models/TContract";
import { PromoLimitStatus } from "app/shared/models/products/PromoLimitStatus";
import { CrudService } from "app/views/others/crud.service";
import { StoreFlatNode } from "app/shared/models/StoreFlatNode";

@Component({
  selector: "app-create-promotion-form",
  templateUrl: "./create-promotion-form.component.html",
  styleUrls: ["./create-promotion-form.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class CreatePromotionFormComponent implements OnInit {
  @Input() data: any;
  @Input() isLoading: boolean = true;
  @Input() user: any;
  @Input() messages?: any = {};
  @Input() readonly?: boolean = false;
  @Output() afterSave: EventEmitter<any> = new EventEmitter(true);
  @ViewChild("form") form: NgForm;
  public itemForm: FormGroup;
  public categories: any;
  public isLoadingCategories: boolean;
  public subCategories: any;
  public isLoadingSubCategories: boolean;
  public filterdSubCategories: any;
  public units: any;
  public isLoadingUnits: boolean;
  public colors: any;
  public isLoadingColors: boolean;
  public genders: any;
  public isLoadingGenders: boolean;
  public ageGroups: any;
  public isLoadingAgeGroups: boolean;
  public fashionSizes: any;
  public isLoadingFashionSizes: boolean;
  public shoeSizes: any;
  public isLoadingShoeSizes: boolean;
  public sizes: any;
  public tContracts: any;
  public isLoadingContracts: boolean;
  public conditions: any;
  public isLoadingConditions: boolean;
  public energyEfficiencyCategories: any;
  public isLoadingEnergyEfficiencyCategory: boolean;
  public showEnergyEfficiencyCategorySelection: boolean;
  public storeTree: any;
  public showSizeSelection: boolean;
  public showStoreSelection: boolean;
  public showDateRangeSelection: boolean;
  public showGTINExternal: boolean;
  public showTContractSelection: boolean;
  public showTContractPopover: boolean;
  public showPriceTypePopover: boolean;
  public showHighlightPromoPopover: boolean;
  public formattedHighlightDate: string;
  public remainingHighlightTime: string;
  public myFile: File;
  public customUploader: CustomFileUploader;
  public promoImgExists: boolean;
  public demoDisplayJsons: Array<DemoDisplay>;
  public errors: Object;
  public priceTypeOptions: Array<Object>;
  public storeSelection: Array<number>;
  public products: Promo[];
  public allStoresAreSelected: boolean;
  public selectedTContract: TContract;
  public userTypeId: number;
  public promoLimitStatus: PromoLimitStatus;
  readonly USER_TYPE_ID_MERCHANT;

  constructor(
    @Inject(LOCALE_ID) public locale,
    @Inject(PLATFORM_ID) public platformId,
    private formBuilder: FormBuilder,
    private crudService: CrudService,
    private http: HttpClient,
    private translate: TranslateService,
    private datepipe: DatePipe,
    private loader: AppLoaderService,
    private adapter: DateAdapter<any>,
    private dialog: MatDialog
  ) {
    this.adapter.setLocale(this.translate.currentLang);
    this.categories = null;
    this.isLoadingCategories = true;
    this.subCategories = null;
    this.isLoadingSubCategories = true;
    this.filterdSubCategories = [];
    this.units = null;
    this.isLoadingUnits = true;
    this.colors = null;
    this.isLoadingColors = true;
    this.genders = null;
    this.isLoadingGenders = true;
    this.ageGroups = null;
    this.isLoadingAgeGroups = true;
    this.fashionSizes = null;
    this.isLoadingFashionSizes = true;
    this.shoeSizes = null;
    this.isLoadingShoeSizes = true;
    this.sizes = null;
    this.tContracts = null;
    this.conditions = null;
    this.isLoadingConditions = true;
    this.energyEfficiencyCategories = null;
    this.isLoadingEnergyEfficiencyCategory = false;
    this.showEnergyEfficiencyCategorySelection = false;
    this.isLoadingContracts = true;
    this.showDateRangeSelection = false;
    this.showStoreSelection = false;
    this.showSizeSelection = false;
    this.showTContractSelection = false;
    this.showTContractPopover = false;
    this.showPriceTypePopover = false;
    this.showHighlightPromoPopover = false;
    this.myFile = null;
    this.demoDisplayJsons = [];
    this.customUploader = new CustomFileUploader(this.http);
    this.remainingHighlightTime = "";
    this.formattedHighlightDate = "";
    this.errors = {};
    this.storeSelection = [];
    this.products = [];
    this.allStoresAreSelected = true;
    this.showGTINExternal = false;
    this.selectedTContract = null;
    this.userTypeId = window.localStorage["userTypeId"];
    this.USER_TYPE_ID_MERCHANT = USER_TYPE_ID_MERCHANT;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.isLoading.currentValue) {
      this.init();
    }
  }

  init() {
    if (this.readonly && this.data.payload.highlightPromo) {
      const appDateAdapter = new AppDateAdapter(
        this.locale,
        this.platformId,
        this.translate
      );

      const relativeTimePipe = new RelativeTimePipe(this.translate);

      this.formattedHighlightDate = appDateAdapter.format(
        this.data.payload.highlightDate,
        "input"
      );

      this.remainingHighlightTime = relativeTimePipe.transform(
        this.data.payload.highlightDate
      );
    }

    if (
      this.data.isGTIN ||
      (this.data.payload.hasOwnProperty("productGTIN_ExternalID") &&
        this.data.payload.productGTIN_ExternalID != null)
    ) {
      this.showGTINExternal = true;
    }

    this.buildItemForm(this.data.payload);
    this.getPromoLimitStatus();
    this.getCategories();
    this.getSubCategories();
    this.getUnits();
    this.getColors();
    this.getGenders();
    this.getAgeGroups();
    this.getFashionSizes();
    this.getConditions();
    this.getTContracts();
    this.getShoeSizes();
    this.getStoreTree();

    this.setDefaultValues(this.data.payload);
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.adapter.setLocale(event.lang);
    });

    this.priceTypeOptions = [
      { label: this.translate.instant("fixedPrice"), value: "fixedPrice" },
      { label: this.translate.instant("noFixedPrice"), value: "noFixedPrice" },
      {
        label: this.translate.instant("priceOnRequest"),
        value: "priceOnRequest",
      },
    ];
  }

  buildItemForm(item): void {
    this.itemForm = this.formBuilder.group(
      {
        product_name: [
          { value: item.product_name || "", disabled: item.autoGenerated },
          Validators.required,
        ],
        product_description: [item.product_description || ""],
        quantity: [
          item.quantity || "1",
          [Validators.required, Validators.min(1)],
        ],
        promo_limit_startdate: [item.promo_limit_startdate || ""], //new change
        promo_limit_enddate: [
          item.promo_limit_enddate || "",
          ValidationService.dateInFuture,
        ], //new
        promo_priority_order: [item.promo_priority_order || ""],
        product_picture: [item.product_picture || ""],
        product_qr: [item.product_qr || ""], //new
        store_ids: [item.store_ids || []],
        isActive: [item.isActive || this.data.isNew],
        highlightPromo: [item.highlightPromo],
        highlightDate: [
          !item.highlightPromo ? null : item.highlightDate || "",
          ValidationService.dateInFuture,
        ],
        stock: [item.stock],
        product_code: [item.product_code || ""],
        promoLink: [item.promoLink || "", [ValidationService.urlValidator]],
        imageIdx: [item.imageIdx],
        product_price_type: [
          { value: "fixedPrice", disabled: item.autoGenerated },
          Validators.required,
        ],
        product_price: [
          { value: item.product_price || "0", disabled: item.autoGenerated },
          [Validators.required, ValidationService.priceGreaterThanZero],
        ],
        product_price_discount: [
          {
            value:
              item.product_price_discount === undefined
                ? null
                : item.product_price_discount,
            disabled: item.autoGenerated,
          },
          [ValidationService.priceGreaterThanZero],
        ], //new
        product_size: [
          item.product_size || "1",
          [Validators.required, Validators.min(1)],
        ], //new
        unit: [item.unit || "", Validators.required], //new
        cate: [item.cate || "", Validators.required], //new
        subCate: [item.subCate || "", Validators.required], //new
        condition: [item.condition || null, Validators.required],
        energyEfficiency: [item.energyEfficiency || null],
        product: [item.product || ""], //new
        promo_id: [item.promo_id || ""], //new
        productImageURL: [item.productImageURL || ""], //new
        promotionImageURL: [item.promotionImageURL || ""], //new
        promo_start_time: [item.promo_start_time || ""], //new
        promo_end_time: [item.promo_end_time || ""], //new
        promoDays: [item.promoDays || null], //new
        useForShop: [item.useForShop || null],
        showStock: [item.showStock || null],
        showRecalculation: [item.showRecalculation || null],
        noFixedPrice: [item.noFixedPrice || null], //NOT anymore preselect if service provider  (userType == 2 ? true :
        priceOnRequest: [item.priceOnRequest || null],
        color: [item.color || "", Validators.required],
        agegroup: [item.agegroup || "", Validators.required],
        gender: [item.gender || "", Validators.required],
        product_brand: [item.product_brand || null],
        productGTIN: [
          { value: item.productGTIN || null, disabled: item.autoGenerated },
        ],
        productGTIN_ExternalID: [
          {
            value: item.productGTIN_ExternalID || null,
            disabled:
              item.productGTIN_ExternalID != null ||
              this.data.isGTIN ||
              item.autoGenerated,
          },
        ],
        sizes: [item.sizes || null],
        tContractId: [item.tContractId || null],
      },
      {
        validator: [
          ValidationService.startDateGreaterThanEndDate(
            "promo_limit_startdate",
            "promo_limit_enddate"
          ),
          ValidationService.discountGreaterThanPrice(
            "product_price",
            "product_price_discount"
          ),
        ],
      }
    );

    if (this.itemForm.get("stock").value) {
      this.itemForm.get("showStock").setValue(true);
    }

    if (
      this.userTypeId == USER_TYPE_ID_SERVICE_PROVIDER ||
      this.userTypeId == USER_TYPE_ID_GASTRO
    ) {
      this.itemForm.controls["product_brand"].setValidators([
        Validators.required,
      ]);
    }

    if (item.productGTIN_ExternalID != null) this.data.isGTIN = true; //brand not mandatory if external GTIN

    this.demoDisplayJsons = [];
    if (item.productImageUrls != null) {
      for (let z = 0; z < item.productImageUrls.length; z++) {
        var demo = new DemoDisplay();
        demo.imageId = item.imageIdx[z];
        demo.productName = item.product_name;
        demo.picture = item.productImageUrls[z];
        if (this.demoDisplayJsons == null) {
          this.demoDisplayJsons = [];
        }
        this.demoDisplayJsons.push(demo);
      }
      const ids = this.demoDisplayJsons.map((c) => c.imageId);
      this.itemForm.get("imageIdx").setValue(ids);
    }

    if (
      this.itemForm.get("product_price").value ==
      this.itemForm.get("product_price_discount").value
    ) {
      this.itemForm.get("product_price_discount").setValue("");
    } else {
      this.itemForm
        .get("product_price_discount")
        .setValue(
          CurrencyService.formatNumber2Price(
            this.itemForm.get("product_price_discount").value
          )
        );
    }

    this.itemForm
      .get("product_price")
      .setValue(
        CurrencyService.formatNumber2Price(
          this.itemForm.get("product_price").value
        )
      );

    if (this.itemForm.get("cate").value === 4) {
      this.showEnergyEfficiencyCategorySelection = true;
      this.getEnergyEfficiencyCategories();
    }

    if (this.itemForm.get("tContractId").value !== null && this.tContracts) {
      this.onChangeTContract(this.itemForm.get("tContractId").value);
    }

    if (this.itemForm.get("priceOnRequest").value != null) {
      this.itemForm.get("product_price_type").setValue("priceOnRequest");
    } else if (this.itemForm.get("noFixedPrice").value != null) {
      this.itemForm.get("product_price_type").setValue("noFixedPrice");
    } else {
      this.itemForm.get("product_price_type").setValue("fixedPrice");
    }

    if (this.readonly) {
      this.itemForm.disable();
    }
  }

  getPromoLimitStatus() {
    this.crudService.getPromoLimitStatus(this.user.StoreId).subscribe((res) => {
      this.promoLimitStatus = res;
    });
  }

  getCategories() {
    this.crudService.getCategory().subscribe((res) => {
      this.categories = res;
      this.isLoadingCategories = false;
    });
  }

  getSubCategories() {
    this.crudService.getStoreSubCategories(null).subscribe((res) => {
      this.subCategories = res;
      this.categoryChanged();
      this.isLoadingSubCategories = false;
    });
  }

  getUnits() {
    this.crudService.getUnit().subscribe((res) => {
      this.units = res;
      this.isLoadingUnits = false;
    });
  }

  getColors() {
    this.crudService.getColors().subscribe((res) => {
      this.colors = res;
      this.isLoadingColors = false;
    });
  }
  getGenders() {
    this.crudService.getGenders().subscribe((res) => {
      this.genders = res;
      this.isLoadingGenders = false;
    });
  }

  getAgeGroups() {
    this.crudService.getAgeGroups(this.user.StoreId).subscribe((res) => {
      this.ageGroups = res;
      this.isLoadingAgeGroups = false;
    });
  }

  getStoreTree() {
    this.crudService
      .getStoreTree(this.user.StoreId, this.user.StoreId)
      .subscribe((res) => {
        this.storeTree = res;

        this.checkAllStoresAreSelected(res);
        this.selectAllStores(res);

        if (!this.data.isNew && !this.allStoresAreSelected) {
          this.showStoreSelection = true;
        }
      });
  }

  setTContractPopoverVisibility(show) {
    this.showTContractPopover = show;
  }

  onChangeTContract(value) {
    this.selectedTContract = this.tContracts.find(
      (contract) => contract.Id == value
    );
  }

  categoryChanged(reset: boolean = false) {
    const newValue = this.itemForm.controls["cate"].value;
    if (newValue) {
      if (this.subCategories) {
        this.filterdSubCategories = this.subCategories.filter(
          (category) => category.CategoryId.toString() == newValue
        );
        if (reset) this.itemForm.get("subCate").setValue(null);
      }

      // Electrical devices
      if (newValue === 4) {
        this.showEnergyEfficiencyCategorySelection = true;

        if (!this.energyEfficiencyCategories) {
          this.getEnergyEfficiencyCategories();
        }
      } else {
        this.showEnergyEfficiencyCategorySelection = false;
        this.itemForm.get("energyEfficiency").setValue(null);
      }
    }
  }

  getSubCategoryById(id: number) {
    return this.filterdSubCategories.find(
      (subCate) => subCate.CategorySubId.toString() == id
    );
  }

  subCategoryChanged(reset: boolean = false) {
    const categoryId = this.itemForm.controls["subCate"].value;
    const sizes = this.itemForm.get("sizes");
    const tContractId = this.itemForm.get("tContractId");

    this.clearSubCategoryValidation();

    if (categoryId) {
      const subCategory = this.getSubCategoryById(categoryId);
      if (categoryId == 36) {
        this.showSizeSelection = true;
        sizes.setValidators([Validators.required]);
        this.sizes = this.shoeSizes;
      } else if (categoryId == 126) {
        this.showTContractSelection = true;
        tContractId.setValidators([Validators.required]);
      } else if (subCategory && subCategory.GoogleNeedCheck) {
        this.showSizeSelection = true;
        sizes.setValidators([Validators.required]);
        this.sizes = this.fashionSizes;
      }
    }

    if (reset) {
      this.itemForm.get("sizes").setValue(null);
      this.itemForm.get("tContractId").setValue(null);
    }

    sizes.updateValueAndValidity();
    tContractId.updateValueAndValidity();
  }

  priceTypeChanged(value) {
    this.itemForm.get("priceOnRequest").setValue(null);
    this.itemForm.get("noFixedPrice").setValue(null);

    if (value === "priceOnRequest") {
      this.itemForm
        .get("product_price")
        .setValue(CurrencyService.addCurrencySymbol("0"));

      this.itemForm.get("product_price_discount").setValue(null);
      this.itemForm.get("priceOnRequest").setValue(true);
    } else if (value === "noFixedPrice") {
      this.itemForm.get("noFixedPrice").setValue(true);
    }
  }

  clearSubCategoryValidation() {
    const sizes = this.itemForm.get("sizes");
    const tContractId = this.itemForm.get("tContractId");

    this.showSizeSelection = false;
    this.showTContractSelection = false;
    tContractId.clearValidators();
    sizes.clearValidators();
  }

  getFashionSizes() {
    this.crudService.getFashionSizes(this.user.StoreId).subscribe((res) => {
      this.fashionSizes = res;
      this.isLoadingFashionSizes = false;
      this.subCategoryChanged();
    });
  }

  getConditions() {
    this.crudService.getConditions(this.user.StoreId).subscribe((res) => {
      this.conditions = res;
      this.isLoadingConditions = false;

      if (
        this.conditions.length &&
        !this.itemForm.controls["condition"].value
      ) {
        this.data.payload.condition = this.conditions[0].Id;
        this.itemForm.controls["condition"].setValue(this.conditions[0].Id);
      }
    });
  }

  getEnergyEfficiencyCategories() {
    this.isLoadingEnergyEfficiencyCategory = true;
    this.crudService
      .getEnergyEfficiencyCategories(this.user.StoreId)
      .subscribe((res) => {
        this.energyEfficiencyCategories = res;
        this.isLoadingEnergyEfficiencyCategory = false;
      });
  }

  getTContracts() {
    this.crudService.getTContracts(this.user.StoreId).subscribe((res) => {
      this.tContracts = res;
      this.isLoadingContracts = false;
    });
  }

  getShoeSizes() {
    this.crudService.getShoeSizes(this.user.StoreId).subscribe((res) => {
      this.shoeSizes = res;
      this.isLoadingShoeSizes = false;
      this.subCategoryChanged();
    });
  }

  getProducts(value: string) {
    this.crudService
      .getProductPagination(5, 0, value, this.user.StoreId)
      .subscribe((res) => {
        this.products = res;
      });
  }

  setDefaultValues(tmpData: any) {
    this.crudService
      .getDefaultValues(this.user.StoreId)
      .subscribe((res: any) => {
        if (
          !tmpData ||
          tmpData.color == null ||
          tmpData.color == 0 ||
          tmpData.agegroup == null ||
          tmpData.agegroup == 0 ||
          tmpData.gender == null ||
          tmpData.gender == 0 ||
          tmpData.unit == null ||
          tmpData.unit == 0 ||
          tmpData.cate == null ||
          tmpData.cate == 0 ||
          tmpData.subCate == null ||
          tmpData.subCate == 0
        ) {
          if (res) {
            //set default values
            tmpData.agegroup = res.AgeGroupDefault;
            tmpData.gender = res.GenderDefault;
            tmpData.color = res.ColorDefault;
            tmpData.unit = res.UnitDefault;
            tmpData.cate = res.CateDefault;
            tmpData.subCate = res.SubCateDefault;
          } else {
            tmpData.agegroup = AGE_GROUP_ID;
            tmpData.gender = GENDER_ID;
            tmpData.color = COLOR_ID;
            tmpData.unit = UNIT_ID;
            tmpData.cate = CATE_ID;
            tmpData.subCate = SUB_CAT_ID;
          }
        }
        this.buildItemForm(tmpData);

        this.categoryChanged();
        this.subCategoryChanged();
      });
  }

  onChangeProductName(event) {
    if (this.data.isGTIN) {
      return;
    }
    this.getProducts(event.target.value);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    const {
      productName,
      productDescription,
      price,
      packageSize,
      productUnitId,
      productCategoryId,
      showRecalculation,
      noFixedPrice,
      quantity,
      productGTIN,
      productGTIN_ExternalID,
      productBrand,
      gender,
      agegroup,
      color,
      productCategorySubId,
      productImageUrls,
      productImageIds,
      tContractId,
    } = event.option.value;

    this.itemForm.get("product_name").setValue(productName);
    this.itemForm.get("product_description").setValue(productDescription);
    this.itemForm.get("product_price").setValue(price);
    this.itemForm.get("product_size").setValue(packageSize);
    this.itemForm.get("unit").setValue(productUnitId);
    this.itemForm.get("cate").setValue(productCategoryId);
    this.itemForm.get("showRecalculation").setValue(showRecalculation);
    this.itemForm.get("noFixedPrice").setValue(noFixedPrice);
    this.itemForm.get("quantity").setValue(quantity);
    this.itemForm.get("tContractId").setValue(tContractId);

    this.itemForm.get("productGTIN").setValue(productGTIN);
    this.itemForm
      .get("productGTIN_ExternalID")
      .setValue(productGTIN_ExternalID);

    this.itemForm.get("product_brand").setValue(productBrand);

    if (gender != 0 && agegroup != 0 && color != 0) {
      this.itemForm.get("gender").setValue(gender);
      this.itemForm.get("agegroup").setValue(agegroup);
      this.itemForm.get("color").setValue(color);
    }

    this.itemForm.get("sizes").setValue([]);
    this.categoryChanged();

    this.itemForm.get("subCate").setValue(productCategorySubId);

    this.itemForm
      .get("product_price")
      .setValue(
        CurrencyService.formatNumber2Price(
          this.itemForm.get("product_price").value
        )
      );

    //Reset discount
    this.itemForm.get("product_price_discount").setValue(null);

    this.subCategoryChanged();

    if (productImageUrls != null) {
      this.demoDisplayJsons = [];

      for (let z = 0; z < productImageUrls.length; z++) {
        var demo = new DemoDisplay();
        demo.imageId = productImageIds[z];
        demo.productName = productName;
        demo.picture = productImageUrls[z];
        if (this.demoDisplayJsons == null) {
          this.demoDisplayJsons = [];
        }
        this.demoDisplayJsons.push(demo);
      }
      const ids = this.demoDisplayJsons.map((c) => c.imageId);
      this.itemForm.get("imageIdx").setValue(ids);
    }
  }

  checkAllStoresAreSelected(storeTree: any) {
    if (!this.itemForm.get("store_ids").value.includes(storeTree.StoreId)) {
      this.allStoresAreSelected = false;
    }
  }

  openStoreSelection() {
    this.showStoreSelection = true;
  }

  onChangeStoreAvailability(event): void {
    this.showStoreSelection = event == 0;

    if (this.showStoreSelection) {
      this.deselectAllStores();
    } else {
      this.selectAllStores(this.storeTree);
    }
  }

  onChangeDateAvailability(event): void {
    this.showDateRangeSelection = event == 0;
    this.toggleDateRangeSelection(this.showDateRangeSelection);
  }

  toggleDateRangeSelection(show: boolean) {
    const startDate = this.itemForm.get("promo_limit_startdate");
    const endDate = this.itemForm.get("promo_limit_enddate");

    if (show) {
      startDate.setValidators([Validators.required]);
      endDate.setValidators([
        Validators.required,
        ValidationService.dateInFuture,
      ]);
    } else {
      startDate.clearValidators();
      endDate.clearValidators();

      this.errors = this.getValidationErrors();
    }

    startDate.updateValueAndValidity();
    endDate.updateValueAndValidity();
  }

  selectStoresRecursive(storeTree: any) {
    this.storeSelection.push(storeTree.StoreId);
    if (!storeTree.Children) {
      return;
    }

    storeTree.Children.forEach((child) => this.selectStoresRecursive(child));
  }

  selectAllStores(storeTree: any): any {
    this.deselectAllStores();
    this.selectStoresRecursive(storeTree);
  }

  deselectAllStores(): void {
    this.storeSelection = [];
  }

  receiveStoreSelection(storeSelection: SelectionModel<StoreFlatNode>) {
    this.setStoreSelection(storeSelection);
  }

  setStoreSelection(storeSelection: SelectionModel<StoreFlatNode>) {
    this.deselectAllStores();
    storeSelection.selected.forEach((item) => {
      this.storeSelection.push(item.StoreId);
    });
  }

  highlightPromoToggleChanged($event) {
    this.itemForm.controls["highlightPromo"].setValue($event.checked);

    const highlightPromo = this.itemForm.get("highlightDate");

    if ($event.checked && highlightPromo.value === "") {
      let highlightDate = new Date();
      highlightDate.setDate(highlightDate.getDate() + 14);
      highlightPromo.setValue(highlightDate);
    }
  }

  statusToggleChanged($event) {
    this.itemForm.controls["isActive"].setValue($event.checked);
  }

  validateNumber($event) {
    const value = $event.target.value.replace(/\D/g, "");
    $event.target.value = value;
  }

  convertPrice($event) {
    $event.target.value = CurrencyService.formatInputPrice($event.target.value);
  }

  onPromoLinkInput($event) {
    let value = $event.target.value;

    this.applyValidatorsForNonEmptyField("promoLink", [
      ValidationService.urlValidator,
    ]);

    $event.target.value = value;
  }

  onDiscountPriceInput($event) {
    let value = CurrencyService.formatInputPrice($event.target.value);
    this.applyValidatorsForNonEmptyField("product_price_discount", [
      ValidationService.priceGreaterThanZero,
    ]);

    $event.target.value = value;
  }

  focusPrice($event) {
    $event.target.value = CurrencyService.removeCurrencySymbol(
      $event.target.value
    );
  }

  blurPrice($event) {
    $event.target.value = CurrencyService.addCurrencySymbol(
      $event.target.value
    );
  }

  resetEnergyEfficiencyCategory($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.itemForm.get("energyEfficiency").setValue(null);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.demoDisplayJsons,
      event.previousIndex,
      event.currentIndex
    );
    const ids = this.demoDisplayJsons.map((c) => c.imageId);
    this.itemForm.get("imageIdx").setValue(ids);
  }

  onfilesDroppedEvent(event): void {
    this.fileSelect(event);
  }

  fileSelect(event) {
    const files: File[] = event;
    this.myFile = <File>event[0];
    this.customUploader.addRange(files);

    for (let i = 0; i < files.length; i++) {
      const newImageFile = files[i];
      const demo = new DemoDisplay();
      const reader = new FileReader();

      reader.onload = (_event) => {
        this.promoImgExists = true;
        demo.productName = newImageFile.name;
        demo.picture = reader.result;
        this.demoDisplayJsons.push(demo);
      };
      reader.readAsDataURL(newImageFile);
    }
  }

  removePhoto(index = null, ocIndex = null) {
    if (index != null) {
      if (this.customUploader != null && this.customUploader.files != null) {
        // if the photo exists in the uploader remove it (this implies that the photo was added but not yet uploaded)
        var cIndex = this.customUploader.files.findIndex(
          (x) => x.myFile.name == this.demoDisplayJsons[index].productName
        );
        if (cIndex > -1) {
          this.customUploader.remove(cIndex);
        }
      }
      // remove the photo from the photo preview in the photos step in the stepper
      this.demoDisplayJsons.splice(index, 1);
    } else {
      if (ocIndex != null) {
        var dIndex = this.demoDisplayJsons.findIndex(
          (x) => x.productName == this.customUploader.files[ocIndex].myFile.name
        );
        if (dIndex != null) {
          this.demoDisplayJsons.splice(dIndex, 1);
        }
        this.customUploader.files.splice(ocIndex, 1);
      } else if (
        this.customUploader != null &&
        this.customUploader.files != null
      ) {
        for (var i = 0; i < this.customUploader.files.length; i++) {
          var dIndex = this.demoDisplayJsons.findIndex(
            (x) => x.productName == this.customUploader.files[i].myFile.name
          );
          if (dIndex != null) {
            this.demoDisplayJsons.splice(dIndex, 1);
          }
        }
        this.customUploader.files = [];
      }
    }
    var ids = this.demoDisplayJsons.map((c) => c.imageId);
    this.itemForm.get("imageIdx").setValue(ids);
    this.promoImgExists = false;
  }

  uploadImages() {
    const myUploader = this.customUploader;
    const imageObservables = this.customUploader.upload();
    const demos = this.demoDisplayJsons;

    return new Promise((resolve, reject) => {
      forkJoin(imageObservables).subscribe(
        (x) => {
          for (var i = 0; i < x.length; i++) {
            if (x[i]["body"] != null) {
              let innerIndex = i;

              myUploader.files[innerIndex].uploaded = true;
              myUploader.files[innerIndex].isSuccess = true;
              myUploader.files[innerIndex].isUploading = false;
              myUploader.files[innerIndex].id = x[i]["body"];

              var myDemo = demos.find(
                (x) => x.productName == myUploader.files[innerIndex].myFile.name
              );
              if (myDemo != null) {
                myDemo.imageId = x[i]["body"];

                var ids = demos.map((c) => c.imageId);
                this.itemForm.get("imageIdx").setValue(ids);
              }
            }
          }
          myUploader.uploadingAll = false;
          myUploader.uploadedAll = true;
          this.customUploader.files = [];

          resolve(x);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  openPreview() {
    var rawPrice = CurrencyService.formatPriceToNumber(
      this.itemForm.get("product_price").value
    );

    var rawDiscount = CurrencyService.formatPriceToNumber(
      this.itemForm.get("product_price_discount").value
    );

    const dialogRef = this.dialog.open(PromotionPreviewDialogComponent, {
      width: "800px",
      maxHeight: "90vh",
      data: {
        images: this.demoDisplayJsons,
        name: this.itemForm.get("product_name").value,
        price: CurrencyService.formatNumber2Price(rawPrice, true),
        priceOnRequest: this.itemForm.get("priceOnRequest").value,
        priceRaw: rawPrice,
        discount: CurrencyService.formatNumber2Price(rawDiscount, true),
        discountRaw: rawDiscount,
        quantity: this.itemForm.get("quantity").value,
        unit: this.units.find(
          (unit) => unit.UnitId == this.itemForm.get("unit").value
        ).UnitName,
        noFixedPrice: this.itemForm.get("noFixedPrice").value,
      },
    });
  }

  validateErrors() {
    this.errors = this.getValidationErrors();
  }

  getValidationErrors(): Object {
    const errors = {};

    if (this.itemForm.hasError("startDateLessThanEndDate")) {
      this.itemForm.controls["promo_limit_enddate"].setErrors({
        startDateLessThanEndDate: true,
      });
    }

    if (this.itemForm.hasError("discountGreaterThanPrice")) {
      this.itemForm.controls["product_price_discount"].setErrors({
        discountGreaterThanPrice: true,
      });
    }

    // FormGroup validation errors
    Object.keys(this.itemForm.controls).forEach((key) => {
      const control = this.itemForm.get(key);
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  setBrandOrGTINError() {
    if (
      (this.itemForm.get("product_brand").value == "" ||
        this.itemForm.get("product_brand").value === null) &&
      (this.itemForm.get("productGTIN").value == "" ||
        this.itemForm.get("productGTIN").value === null)
    ) {
      this.itemForm.controls["product_brand"].setErrors({
        brandOrGtinIsRequired: true,
      });
      this.itemForm.controls["productGTIN"].setErrors({
        brandOrGtinIsRequired: true,
      });
    } else {
      this.itemForm.controls["product_brand"].setErrors({
        brandOrGtinIsRequired: null,
      });
      this.itemForm.controls["productGTIN"].setErrors({
        brandOrGtinIsRequired: null,
      });

      this.itemForm.controls["product_brand"].updateValueAndValidity();
      this.itemForm.controls["productGTIN"].updateValueAndValidity();
    }
  }

  resetForm() {
    this.buildItemForm(this.data.payload);
    this.setDefaultValues(this.data.payload);
    this.selectAllStores(this.storeTree);
    this.showStoreSelection = false;
  }

  async proceedSubmit() {
    if (!this.itemForm.controls["isActive"].value) {
      const endDate = this.itemForm.get("promo_limit_enddate");
      let newEndDate = new Date();
      newEndDate.setDate(newEndDate.getDate() - 1);
      endDate.setValue(newEndDate);
    }

    this.itemForm
      .get("product_price")
      .setValue(
        CurrencyService.formatPriceToNumber(
          this.itemForm.get("product_price").value
        )
      );

    if (this.itemForm.get("product_price_discount").value != "") {
      this.itemForm
        .get("product_price_discount")
        .setValue(
          CurrencyService.formatPriceToNumber(
            this.itemForm.get("product_price_discount").value
          )
        );
    }

    if (!this.itemForm.get("showStock").value) {
      this.itemForm.get("stock").setValue(null);
    }

    const value = this.itemForm.getRawValue();
    const request = this.data.isNew
      ? this.crudService.addItem(value, this.user.StoreId)
      : this.crudService.updateItem(
          this.data.payload._id,
          value,
          this.user.StoreId
        );

    let success = true;
    this.loader.open().subscribe(() => {
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
    });

    let result = null;

    result = await request.toPromise().catch((e) => {
      this.errors["global"] = e.error;
      success = false;
    });

    this.loader.close();

    if (success) {
      this.afterSave.emit({ result: { success, result } });
    }
  }

  applyValidatorsForNonEmptyField(name, validators) {
    if (this.itemForm.get(name).value === "") {
      this.itemForm.get(name).clearValidators();
    } else {
      this.itemForm.get(name).setValidators(validators);
    }
    this.itemForm.get(name).updateValueAndValidity();
  }

  async onSubmit() {
    this.messages = {};

    this.applyValidatorsForNonEmptyField("product_price_discount", [
      ValidationService.priceGreaterThanZero,
    ]);

    this.applyValidatorsForNonEmptyField("promoLink", [
      ValidationService.urlValidator,
    ]);

    for (var i in this.itemForm.controls) {
      this.itemForm.controls[i].markAsTouched();
    }

    if (
      (!this.data.isGTIN ||
        !this.itemForm.get("productGTIN_ExternalID").value) &&
      this.userTypeId == USER_TYPE_ID_MERCHANT
    ) {
      this.setBrandOrGTINError();
    }

    const startDate = this.itemForm.get("promo_limit_startdate");
    const endDate = this.itemForm.get("promo_limit_enddate");

    if (this.itemForm.invalid) {
      this.errors = this.getValidationErrors();
      this.errors["global"] = this.translate.instant("createPromoFormFailure");

      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      return;
    }

    // Store validation
    if (this.storeSelection.length <= 0) {
      this.errors["global"] = this.translate.instant(
        "createPromoFormMinStoreFailure"
      );
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      return;
    }

    this.itemForm.get("store_ids").setValue(this.storeSelection);

    // Format date
    if (startDate.value != "") {
      this.itemForm
        .get("promo_limit_startdate")
        .setValue(this.datepipe.transform(startDate.value, "yyyy-MM-dd"));
    }

    if (endDate.value != "") {
      this.itemForm
        .get("promo_limit_enddate")
        .setValue(this.datepipe.transform(endDate.value, "yyyy-MM-dd"));
    }

    if (this.customUploader.files != null) {
      try {
        await this.uploadImages();
      } catch (e) {
        this.errors["global"] = e.error;
        document.querySelector(".main-content-wrap").scrollTo(0, 0);
        return;
      }
    }

    // Image validation
    if (
      this.itemForm.get("imageIdx").value == null ||
      this.itemForm.get("imageIdx").value.length <= 0
    ) {
      const dialogRef = this.dialog.open(
        NoImagesUploadedConfirmationDialogComponent,
        {
          width: "800px",
        }
      );

      dialogRef.afterClosed().subscribe((res) => {
        if (res && res.proceed) {
          this.proceedSubmit();
        }
      });

      return;
    } else {
      this.proceedSubmit();
    }
  }
}
