export class FacebookPageDetails {
  public id: number;
  public facebookId: string;
  public facebookLink: string;
  public pageName: string;
  public instagramId: string;
  public instagramName: string;
  public storeId?: number;
  public postingWeekCount: number;
  public adsPermissionStatus?: boolean;
}
