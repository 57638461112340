import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatSnackBar,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ExternalSystem } from "app/shared/models/ExternalSystem";
import { ExternalSystemCategory } from "app/shared/models/ExternalSystemCategory";
import { ExternalSystemUserInformation } from "app/shared/models/ExternalSystemUserInformation";
import { User } from "app/shared/models/User";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/others/crud.service";

import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "settings-external-system-tags",
  templateUrl: "./settings-external-system-tags.component.html",
  styleUrls: ["./settings-external-system-tags.component.scss"],
})
export class SettingsExternalSystemTagsComponent implements OnInit {
  @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;
  @Input() user: User;
  @Input() externalSystem: ExternalSystem;
  @Input() externalSystemInformation: ExternalSystemUserInformation;
  @Input() externalSystemConfig: Object;
  @Input() selectedTagsIds: string[];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public tagCtrl = new FormControl();
  public filteredTags: Observable<ExternalSystemCategory[]>;
  public unselectedTags: ExternalSystemCategory[] = [];
  public tags: ExternalSystemCategory[];
  public tagSelectionChanged: boolean;
  public allTagsIsChecked: boolean;
  public selectedTags: ExternalSystemCategory[] = [];
  public isLoadingTags: boolean;
  constructor(
    private crudService: CrudService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private translate: TranslateService
  ) {
    this.tagSelectionChanged = false;
    this.isLoadingTags = false;
    this.tags = null;
  }

  ngOnInit() {
    this.allTagsIsChecked = !this.selectedTagsIds.length;

    if (!this.allTagsIsChecked) {
      this.getExternalSystemTags();
    }
  }

  initTagSelection() {
    this.updateFilter();
  }

  updateFilter() {
    this.updateSelectedTagsIds();
    this.unselectedTags = this.getUnselectedTags();

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: ExternalSystemCategory | null) =>
        tag ? this._filter(tag) : this.unselectedTags.slice()
      )
    );
  }

  getSelectedTags() {
    return this.tags.filter((tag) => this.selectedTagsIds.includes(tag.Id));
  }

  getUnselectedTags() {
    return this.tags.filter((tag) => !this.selectedTagsIds.includes(tag.Id));
  }

  updateSelectedTagsIds() {
    const temp: string[] = [];
    this.selectedTags.forEach((selectedTag) => {
      temp.push(selectedTag.Id);
    });

    this.selectedTagsIds = temp;
  }

  private _filter(
    value: ExternalSystemCategory | string
  ): ExternalSystemCategory[] {
    let filterValue = typeof value === "string" ? value : value.Name;
    filterValue = filterValue.toLowerCase();

    return this.unselectedTags.filter((tag) =>
      tag.Name.toLowerCase().includes(filterValue)
    );
  }

  tagToggleChanged($event) {
    this.allTagsIsChecked = $event.checked;

    if (this.allTagsIsChecked) {
      this.tagSelectionChanged = true;
    } else {
      if (!this.tags) {
        this.getExternalSystemTags();
      }
    }
  }

  async getExternalSystemTags() {
    this.isLoadingTags = true;

    this.tags = await this.crudService
      .GetExternalSystemAllTags(this.externalSystem.Id, this.user.StoreId)
      .toPromise();
    this.selectedTags = this.getSelectedTags();
    this.initTagSelection();
    this.isLoadingTags = false;
  }

  add(event: MatChipInputEvent): void {
    this.tagCtrl.setValue(null);
  }

  remove(value: ExternalSystemCategory): void {
    this.tagSelectionChanged = true;
    const index = this.selectedTags.findIndex((tag) => tag.Id == value.Id);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.updateFilter();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tagSelectionChanged = true;
    const value = this.tags.find((tag) => tag.Name === event.option.viewValue);

    this.selectedTags.push(value);
    this.tagInput.nativeElement.value = "";
    this.tagCtrl.setValue(null);

    this.updateFilter();
  }

  saveTags() {
    if (this.allTagsIsChecked) {
      this.selectedTags = [];
    }

    this.loader.open();
    this.crudService
      .UpdateExternalSystemSelectedTags(
        this.externalSystem.Id,
        this.selectedTags,
        this.user.StoreId
      )
      .subscribe(
        (succ) => {
          this.loader.close();
          if (succ) {
            this.snack.open(this.translate.instant("tagsUpdated"), "OK", {
              duration: 4000,
            });
            this.tagSelectionChanged = false;
          } else {
            this.snack.open(this.translate.instant("tagsNotUpdated"), "OK", {
              duration: 4000,
            });
          }
        },
        () => {
          this.loader.close();
        }
      );
  }
}
