import { Component, Input, LOCALE_ID, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "app/shared/models/payments/Subscription";
import { CUSTOM_DATE_FORMATS } from "../../../../../../constants";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";

@Component({
  selector: "subscriptions-preview-table",
  templateUrl: "./subscriptions-preview-table.component.html",
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        return translate.currentLang;
      },
      deps: [TranslateService],
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  styleUrls: ["./subscriptions-preview-table.component.scss"],
})
export class SubscriptionsPreviewTableComponent implements OnInit {
  @Input() subscriptions: Subscription[];
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    registerLocaleData(localeDe, "de");
  }

  getRowClass(row) {
    return {
      "new-entry": row.NewEntry,
    };
  }
}
