import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { User } from "app/shared/models/User";

@Component({
  selector: "google-account-status-card",
  templateUrl: "./google-account-status-card.component.html",
  styleUrls: ["./google-account-status-card.component.scss"],
})
export class GoogleAccountStatusCardComponent implements OnInit {
  @Output() createGoogleAccount: EventEmitter<any> = new EventEmitter();
  @Input() allGoogleStatus: any;
  @Input() googleAccount: any;
  @Input() user: User;
  public activeStatus: any;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.setActiveStatus();
  }

  ngOnInit() {}

  setActiveStatus() {
    this.activeStatus = this.allGoogleStatus.find(
      (status) => status.Id == this.googleAccount.Status
    );
  }
}
