import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserVideoTemplate } from "app/shared/models/video-templates/UserVideoTemplate";

@Component({
  selector: "video-template-card",
  templateUrl: "./video-template-card.component.html",
  styleUrls: ["./video-template-card.component.scss"],
})
export class VideoTemplateCardComponent implements OnInit {
  @Input() videoTemplate: UserVideoTemplate;
  @Input() selectedTemplateId: string;
  @Output() onSelectVideoTemplateId: EventEmitter<any> = new EventEmitter(true);
  constructor() {}

  ngOnInit() {}
}
