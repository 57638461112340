import { Component, Input, OnInit } from "@angular/core";
import { ProductChannelInformation } from "app/shared/models/ProductChannelInformation";

@Component({
  selector: "app-basic-channels-grid",
  templateUrl: "./basic-channels-grid.component.html",
  styleUrls: ["./basic-channels-grid.component.scss"],
})
export class BasicChannelsGridComponent implements OnInit {
  @Input() channels: ProductChannelInformation[];
  constructor() {}

  ngOnInit() {}
}
