import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically
  
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    if(localStorage['token'] == null) 
      this.isAuthenticated = false;
    else
      this.isAuthenticated = true;

    //Expired??
    if(new Date(localStorage['expiry']) < new Date())
    {
      localStorage['token'] = null;
      this.isAuthenticated = false;
    }
 
    if (this.isAuthenticated) {
      return true
    }
    this.router.navigate(['/sessions/signin']);
    return false;
  }
}