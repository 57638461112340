import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { StoreFlatNode } from "app/shared/models/StoreFlatNode";
import { StoreNode } from "app/shared/models/StoreNode";
@Component({
  selector: "app-store-selection-tree",
  templateUrl: "./store-selection-tree.component.html",
  styleUrls: ["./store-selection-tree.component.scss"],
})
export class StoreSelectionTreeComponent implements OnInit {
  @Input() readonly?: boolean = false;
  @Input() storeTree: any;
  @Input() storeIds: Array<number>;
  @Output() storeSelection: EventEmitter<SelectionModel<StoreFlatNode>> =
    new EventEmitter<SelectionModel<StoreFlatNode>>();
  public allStoresSelected: boolean;

  flatNodeMap = new Map<StoreFlatNode, StoreNode>();

  nestedNodeMap = new Map<StoreNode, StoreFlatNode>();

  selectedParent: StoreFlatNode | null = null;

  treeControl: FlatTreeControl<StoreFlatNode>;

  treeFlattener: MatTreeFlattener<StoreNode, StoreFlatNode>;

  dataSource: MatTreeFlatDataSource<StoreNode, StoreFlatNode>;

  checklistSelection = new SelectionModel<StoreFlatNode>(true /* multiple */);

  constructor() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      (node) => node.Level,
      (node) => node.Expandable,
      (node) => node.Children
    );
    this.treeControl = new FlatTreeControl<StoreFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    this.allStoresSelected = true;
  }

  ngOnInit() {
    this.dataSource.data = [this.storeTree];
    this.checkAllByStoreId(this.storeIds);
  }

  getLevel = (node: StoreFlatNode) => node.Level;

  isExpandable = (node: StoreFlatNode) => node.Expandable;

  getChildren = (node: StoreNode): StoreNode[] => node.Children;

  hasChild = (_: number, _nodeData: StoreFlatNode) => _nodeData.Expandable;

  hasNoContent = (_: number, _nodeData: StoreFlatNode) => _nodeData.Name === "";

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: StoreNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.Name === node.Name
        ? existingNode
        : new StoreFlatNode();
    flatNode.Name = node.Name;
    flatNode.Level = level;
    flatNode.StoreId = node.StoreId;
    flatNode.latitude = node.latitude;
    flatNode.longitude = node.longitude;
    flatNode.isVirtual = node.isVirtual;
    flatNode.Expandable = !!node.Children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  descendantsAllSelected(node: StoreFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: StoreFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the store item selection. Select/deselect all the descendants node */
  storeItemSelectionToggle(node: StoreFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.sendStoreSelection();
  }

  /** Toggle a leaf store item selection. Check all the parents to see if they changed */
  storeLeafItemSelectionToggle(node: StoreFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);

    this.sendStoreSelection();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: StoreFlatNode): void {
    let parent: StoreFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkAllByStoreId(storeIds: Array<number>) {
    this.treeControl.dataNodes.forEach((node) => {
      if (storeIds.includes(node.StoreId)) {
        this.storeItemSelectionToggle(node);
      }
    });
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: StoreFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }

    this.sendStoreSelection();
  }

  /* Get the parent node of a node */
  getParentNode(node: StoreFlatNode): StoreFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  sendStoreSelection(): void {
    this.storeSelection.emit(this.checklistSelection);
  }
}
