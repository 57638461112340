import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "campaign-customer-action-card",
  templateUrl: "./campaign-customer-action-card.component.html",
  styleUrls: ["./campaign-customer-action-card.component.scss"],
})
export class CampaignCustomerActionCardComponent implements OnInit {
  @Input() customer: any;
  @Input() height: number = 200;
  @Input() imageSize: number = 60;
  @Input() horizontal: boolean = false;
  @Input() selectable: boolean = true;
  @Input() showCheck: boolean = false;
  @Input() showLabel: boolean = false;
  constructor() {}

  ngOnInit() {}
}
