import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatSnackBar,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ExternalSystem } from "app/shared/models/ExternalSystem";
import { ExternalSystemCategory } from "app/shared/models/ExternalSystemCategory";
import { ExternalSystemUserInformation } from "app/shared/models/ExternalSystemUserInformation";
import { User } from "app/shared/models/User";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/others/crud.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "settings-external-system-categories",
  templateUrl: "./settings-external-system-categories.component.html",
  styleUrls: ["./settings-external-system-categories.component.scss"],
})
export class SettingsExternalSystemCategoriesComponent implements OnInit {
  @ViewChild("categoryInput") categoryInput: ElementRef<HTMLInputElement>;
  @Input() user: User;
  @Input() externalSystem: ExternalSystem;
  @Input() externalSystemInformation: ExternalSystemUserInformation;
  @Input() externalSystemConfig: Object;
  @Input() selectedCategoryIds: string[];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public categoryCtrl = new FormControl();
  public filteredCategories: Observable<ExternalSystemCategory[]>;
  public unselectedCategories: ExternalSystemCategory[] = [];
  public categories: ExternalSystemCategory[];
  public categorySelectionChanged: boolean;
  public allCategoriesIsChecked: boolean;
  public selectedCategories: ExternalSystemCategory[] = [];
  public isLoadingCategories: boolean;

  constructor(
    private crudService: CrudService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private translate: TranslateService
  ) {
    this.categorySelectionChanged = false;
    this.isLoadingCategories = false;
    this.categories = null;
  }

  ngOnInit() {
    this.allCategoriesIsChecked = !this.selectedCategoryIds.length;

    if (!this.allCategoriesIsChecked) {
      this.getExternalSystemCategories();
    }
  }

  initCategorySelection() {
    this.updateFilter();
  }

  updateFilter() {
    this.updateSelectedCategoriesIds();
    this.unselectedCategories = this.getUnselectedCategories();

    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      map((category: ExternalSystemCategory | null) =>
        category ? this._filter(category) : this.unselectedCategories.slice()
      )
    );
  }

  getSelectedCategories() {
    return this.categories.filter((category) =>
      this.selectedCategoryIds.includes(category.Id)
    );
  }

  getUnselectedCategories() {
    return this.categories.filter(
      (category) => !this.selectedCategoryIds.includes(category.Id)
    );
  }

  updateSelectedCategoriesIds() {
    const temp: string[] = [];
    this.selectedCategories.forEach((selectedCategory) => {
      temp.push(selectedCategory.Id);
    });

    this.selectedCategoryIds = temp;
  }

  private _filter(
    value: ExternalSystemCategory | string
  ): ExternalSystemCategory[] {
    let filterValue = typeof value === "string" ? value : value.Name;
    filterValue = filterValue.toLowerCase();

    return this.unselectedCategories.filter((category) =>
      category.Name.toLowerCase().includes(filterValue)
    );
  }

  categoryToggleChanged($event) {
    this.allCategoriesIsChecked = $event.checked;

    if (this.allCategoriesIsChecked) {
      this.categorySelectionChanged = true;
    } else {
      if (!this.categories) {
        this.getExternalSystemCategories();
      }
    }
  }

  async getExternalSystemCategories() {
    this.isLoadingCategories = true;

    this.categories = await this.crudService
      .GetExternalSystemAllCategories(this.externalSystem.Id, this.user.StoreId)
      .toPromise();
    this.selectedCategories = this.getSelectedCategories();
    this.initCategorySelection();
    this.isLoadingCategories = false;
  }

  add(event: MatChipInputEvent): void {
    this.categoryCtrl.setValue(null);
  }

  remove(value: ExternalSystemCategory): void {
    this.categorySelectionChanged = true;
    const index = this.selectedCategories.findIndex(
      (category) => category.Id == value.Id
    );
    if (index >= 0) {
      this.selectedCategories.splice(index, 1);
      this.updateFilter();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.categorySelectionChanged = true;
    const value = this.categories.find(
      (category) => category.Name === event.option.viewValue
    );

    this.selectedCategories.push(value);
    this.categoryInput.nativeElement.value = "";
    this.categoryCtrl.setValue(null);

    this.updateFilter();
  }

  saveCategories() {
    if (this.allCategoriesIsChecked) {
      this.selectedCategories = [];
    }

    this.loader.open();
    this.crudService
      .UpdateExternalSystemSelectedCategories(
        this.externalSystem.Id,
        this.selectedCategories,
        this.user.StoreId
      )
      .subscribe(
        (succ) => {
          this.loader.close();
          if (succ) {
            this.snack.open(this.translate.instant("categoriesUpdated"), "OK", {
              duration: 4000,
            });
            this.categorySelectionChanged = false;
          } else {
            this.snack.open(
              this.translate.instant("categoriesNotUpdated"),
              "OK",
              {
                duration: 4000,
              }
            );
          }
        },
        () => {
          this.loader.close();
        }
      );
  }
}
