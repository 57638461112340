import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { MAX_UPLOAD_SIZE } from "../../../../../constants";
import { UploadImageDialogComponent } from "../../dialogs/upload-image-dialog/upload-image-dialog.component";

@Component({
  selector: "image-upload-icon-button",
  templateUrl: "./image-upload-icon-button.component.html",
  styleUrls: ["./image-upload-icon-button.component.scss"],
})
export class ImageUploadIconButtonComponent implements OnInit {
  files: string[] = [];
  @Input() removeBackground?: boolean = false;
  @Output() filesUploaded = new EventEmitter<string[]>();
  @Output() filesUploadedEvent = new EventEmitter<any>();
  constructor(
    private snack: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  emitFiles(event) {
    const files = event.target.files;
    let error = false;

    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      const fileSize = Number((element.size / 1024 / 1024).toFixed(4));

      if (fileSize > MAX_UPLOAD_SIZE) {
        error = true;
        this.snack.open(
          this.translate.instant("ImageUploadMaxSizeError", {
            maxUploadSize: MAX_UPLOAD_SIZE,
          }),
          "OK",
          {
            duration: 4000,
          }
        );
        break;
      } else {
        const dialogRef = this.dialog.open(UploadImageDialogComponent, {
          width: "1000px",
          maxHeight: "95vh",
          data: {
            file: element,
            useBackgroundRemover: this.removeBackground,
            useImageCropper: true,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.files.push(res.res.name);
            this.filesUploaded.emit(this.files);
            this.filesUploadedEvent.emit([res.res]);
          }
        });
      }
    }

    event.target.value = "";
  }
}
