import { Component, Input, OnInit } from "@angular/core";
import { ExternalSystem } from "app/shared/models/ExternalSystem";
import { ExternalSystemUserInformation } from "app/shared/models/ExternalSystemUserInformation";

@Component({
  selector: "settings-external-system-information",
  templateUrl: "./settings-external-system-information.component.html",
  styleUrls: ["./settings-external-system-information.component.scss"],
})
export class SettingsExternalSystemInformationComponent implements OnInit {
  @Input() externalSystem: ExternalSystem;
  @Input() externalSystemInformation: ExternalSystemUserInformation;
  @Input() externalSystemConfig: any;
  public displayItems: any;
  constructor() {}

  ngOnInit() {
    this.displayItems = this.getDisplayItems();
  }

  getDisplayItems() {
    return this.externalSystemConfig.properties.filter(
      (property) => property.display
    );
  }
}
