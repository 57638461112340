import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smartphone-iphone-x',
  templateUrl: './smartphone-iphone-x.component.html',
  styleUrls: ['./smartphone-iphone-x.component.scss']
})
export class SmartphoneIphoneXComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
