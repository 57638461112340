import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";

@Component({
  selector: "external-systems-supported-import-formats",
  templateUrl: "./external-systems-supported-import-formats.component.html",
  styleUrls: ["./external-systems-supported-import-formats.component.scss"],
})
export class ExternalSystemsSupportedImportFormatsComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  openSupportPopover() {}
}
