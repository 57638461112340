import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Global } from "../../../../../../global";

@Component({
  selector: "marketing-portal-smcampaign-overview",
  templateUrl: "./marketing-portal-smcampaign-overview.component.html",
  styleUrls: ["./marketing-portal-smcampaign-overview.component.scss"],
})
export class MarketingPortalSmcampaignOverviewComponent implements OnInit {
  @Input() socialMediaCampaign: any;
  public previewMediaFiles: any;
  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.buildMediaFilesArr();
  }

  buildMediaFilesArr() {
    this.previewMediaFiles = [];

    if (this.socialMediaCampaign.mediaAssets.length === 0) {
      return;
    }

    let mediaAsset = this.socialMediaCampaign.mediaAssets[0];
    this.previewMediaFiles.push({
      safeData: this.domSanitizer.bypassSecurityTrustUrl(
        Global.ImageUrl +
          "api/Public/GetMediaAsset?id=" +
          mediaAsset.mediaAssetId
      ),
      isVideo: mediaAsset.isVideo,
    });
  }
}
