import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { environment } from "environments/environment";
import { Global } from "global";

@Component({
  selector: "campaign-gdn-ads-preview",
  templateUrl: "./campaign-gdn-ads-preview.component.html",
  styleUrls: ["./campaign-gdn-ads-preview.component.scss"],
})
export class CampaignGdnAdsPreviewComponent implements OnInit {
  @Input() imageInfo: any;
  @Input() description: string;
  @Input() headline: string;
  @Input() businessName: string;
  @Input() callToActionText: string = "Mehr";
  public logoImageUrl: string;
  public landscaleImageUrl: string;
  public environment = environment;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("imageInfo")) {
      this.setImageUrls();
    }
  }

  setImageUrls() {
    let logoImageIndex = this.imageInfo.findIndex(
      (image) => image.googleDisplayMediaFormatId === 2
    );

    this.logoImageUrl =
      logoImageIndex != -1
        ? this.buildImageUrl(this.imageInfo[logoImageIndex].mediaAssetId)
        : null;

    let landscaleImageIndex = this.imageInfo.findIndex(
      (image) => image.googleDisplayMediaFormatId === 3
    );

    this.landscaleImageUrl =
      landscaleImageIndex != -1
        ? this.buildImageUrl(this.imageInfo[landscaleImageIndex].mediaAssetId)
        : null;
  }

  buildImageUrl(mediaAssetId) {
    return Global.ImageUrl + "api/Public/GetMediaAsset?id=" + mediaAssetId;
  }
}
