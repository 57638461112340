import { Component, Input, LOCALE_ID, OnInit } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import localeDe from "@angular/common/locales/de";
import { CUSTOM_DATE_FORMATS } from "../../../../../constants";
import { Subscription } from "app/shared/models/payments/Subscription";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";

@Component({
  selector: "subscriptions-detail-table",
  templateUrl: "./subscriptions-detail-table.component.html",
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        return translate.currentLang;
      },
      deps: [TranslateService],
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  styleUrls: ["./subscriptions-detail-table.component.scss"],
})
export class SubscriptionsDetailTableComponent implements OnInit {
  @Input() subscriptions: Subscription[];
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    registerLocaleData(localeDe, "de");
    this.generateEndTime();
  }

  generateEndTime() {
    this.subscriptions.forEach((item, i) => {
      if (i === 0) {
        this.subscriptions[i].EndDate = "-";
      } else {
        this.subscriptions[i].EndDate = this.subscriptions[i - 1].StartDate;
      }
    });
  }
}
