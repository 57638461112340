import { MPCampaignStatistics } from "./MPCampaignStatistics";
import { MPCampaignStatus } from "./MPCampaignStatus";
import { MPCampaignPackage } from "./MPCampaingPackage";
import { MPCustomer } from "./MPCustomer";
import { MPDevice } from "./MPDevice";
import { MPGDNCallToAction } from "./MPGDNCallToAction";
import { MPGDNMediaAsset } from "./MPGDNMediaAsset";
import { MPGender } from "./MPGender";
import { MPGoalAndPerformance } from "./MPGoalAndPerformance";
import { MPLocation } from "./MPLocation";
import { MPMediaAsset } from "./MPMediaAsset";
import { SMCampaignType } from "./SMCampaignType";

export class MPGDNCampaign {
  static readonly CHANNEL_ID = 10;

  public mpCampaignId: number;
  public googleDisplayCampaignId: number;
  public dateTimeDisplayName: string;
  public start: Date;
  public end: Date;
  public status: MPCampaignStatus;
  public name: string;
  public displayBudget: number;
  public package: MPCampaignPackage;
  public goalAndPerformance: MPGoalAndPerformance;

  public googleDisplayCampaignBusinessName: string;
  public googleDisplayCampaignFinalUrl: string;

  public googleDisplayCampaignLongHeadlines: string;
  public googleDisplayCampaignHeadlines: string[];
  public googleDisplayCampaignDescriptions: string[];

  public mediaAssets: MPGDNMediaAsset[];
  public gender: MPGender;
  public device: MPDevice;
  public googleDisplayCampaignCallToAction: MPGDNCallToAction;

  public fromAge: number;
  public toAge: number;
  public targets: any[];
  public locations: MPLocation[];
  public linkToPreview: string;
  public createdOn: Date;
  public campaignStatistics: MPCampaignStatistics;
  public customer: MPCustomer;
  public showApproalButton: boolean;
  public channelId: number;
  public isPayed: boolean;

  constructor() {}
  hasBudget() {
    return this.displayBudget || this.package;
  }
}
