import { Component, Input, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { FacebookPageDetails } from "app/shared/models/FacebookPageDetails";
import { User } from "app/shared/models/User";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/others/crud.service";

@Component({
  selector: "facebook-page-detail-card",
  templateUrl: "./facebook-page-detail-card.component.html",
  styleUrls: ["./facebook-page-detail-card.component.scss"],
})
export class FacebookPageDetailCardComponent implements OnInit {
  @Input() page: FacebookPageDetails;
  @Input() user: User;
  public requestSent: boolean = false;
  public postCreated: boolean = false;
  public editPostFrequency: boolean = false;
  public newPostFrequency: number;
  constructor(
    private crudService: CrudService,
    private loader: AppLoaderService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.newPostFrequency = this.page.postingWeekCount;
  }

  savePostFrequencyEdit() {
    this.loader.open();

    this.crudService
      .changeFacebookPostingFrequencyForPage(
        this.page.id,
        this.user.StoreId,
        this.newPostFrequency
      )
      .subscribe((res) => {
        this.loader.close();
        if (res) {
          this.snackBar.open("Frequenz der Posts erfolgreich geändert!", "OK", {
            duration: 4000,
            horizontalPosition: "end",
            verticalPosition: "bottom",
            panelClass: ["success-snackbar"],
          });
          this.page.postingWeekCount = this.newPostFrequency;
        } else {
          this.snackBar.open("Unbekannter Fehler ist aufgetreten", "OK", {
            duration: 4000,
            horizontalPosition: "end",
            verticalPosition: "bottom",
            panelClass: ["error-snackbar"],
          });
        }
      });

    this.editPostFrequency = false;
  }

  closePostFrequencyEdit() {
    this.editPostFrequency = false;
  }

  createPosts() {
    this.loader.open();

    this.crudService
      .createFacebookPagePosts(this.page.id, this.user.StoreId)
      .subscribe((res) => {
        this.loader.close();

        if (res) {
          this.snackBar.open("Facebook Posts erfolgreich erstellt!", "OK", {
            duration: 4000,
            horizontalPosition: "end",
            verticalPosition: "bottom",
            panelClass: ["success-snackbar"],
          });
          this.postCreated = true;
        } else {
          this.snackBar.open(
            "Fehler beim Erstellen von Facebook Posts aufgetreten",
            "OK",
            {
              duration: 4000,
              horizontalPosition: "end",
              verticalPosition: "bottom",
              panelClass: ["error-snackbar"],
            }
          );
        }
      });
  }

  linkPage() {
    this.loader.open();

    this.crudService
      .linkFacebookPage(this.page.facebookId, this.user.StoreId)
      .subscribe((res) => {
        this.loader.close();

        if (res) {
          this.snackBar.open("Anfrage erfolgreich versendet!", "OK", {
            duration: 4000,
            horizontalPosition: "end",
            verticalPosition: "bottom",
            panelClass: ["success-snackbar"],
          });
          this.requestSent = true;
        } else {
          this.snackBar.open(
            "Fehler beim Senden der Anfrage aufgetreten",
            "OK",
            {
              duration: 4000,
              horizontalPosition: "end",
              verticalPosition: "bottom",
              panelClass: ["error-snackbar"],
            }
          );
        }
      });
  }
}
