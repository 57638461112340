export class User {
  public Id: string;
  public StoreOwnerName: string;
  public Email: string;
  public StoreId: number;
  public StoreName: string;
  public Imprint: string;
  public CreatedDate: Date;
  public PromoCode: string;
  public PromoCount: number;
  public AssistentShown: boolean;
  public AppUser: boolean;
  public EmailConfirmed: boolean;
  public CurrentBudget: number;
  public CurrentSubscriptionBudget: number;
  public ExternalSystem: number;
  public GoogleStatus: number;
  public GoogleSetupCompletely: boolean;
  public FacebookStatus: number;
  public FacebookSetupCompletely: boolean;
  public InstaConnected: boolean;
  public LanguageId: number;
  public NewEntry?: boolean;
}
