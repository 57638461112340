import { Component, Input, OnInit } from "@angular/core";
import { SMCampaign } from "app/shared/models/marketing-portal/SMCampaign";
import { CrudService } from "app/views/others/crud.service";

@Component({
  selector: "sm-ads-campaign-details",
  templateUrl: "./sm-ads-campaign-details.component.html",
  styleUrls: ["./sm-ads-campaign-details.component.scss"],
})
export class SmAdsCampaignDetailsComponent implements OnInit {
  @Input() mpCampaignId: number;
  @Input() userId: string;
  public socialMediaCampaign: any;
  public isLoading: boolean = true;
  constructor(private crudService: CrudService) {}

  async ngOnInit() {
    this.socialMediaCampaign = await this.crudService
      .getSocialMediaCampaignByMPCampaign(this.mpCampaignId, this.userId)
      .toPromise();

    this.isLoading = false;
  }
}
