import { HttpHeaders } from "@angular/common/http";

export class Global {
  //TEST ENV
  // public static BackendUrl: String = "https://x.mapads.net/internal2/api/";
  // public static BackendTokenUrl: String = "https://x.mapads.net/internal2/";
  // public static ImageUrl: String = "https://testsystem.mapads.net/ImageApi/";
  // public static ExternalSystemsUrl: String =
  //   "https://testsystem.mapads.net/ExternalSystems/api/";
  // public static GoogleUrl: String = "https://testsystem.mapads.net/Google/api/";
  // public static FacebookUrl: String =
  //   "https://testsystem.mapads.net/Facebook/api/";
  //PROD ENV
  public static BackendUrl: String = "https://x.mapads.net/internal/api/";
  public static BackendTokenUrl: String = "https://x.mapads.net/internal/";
  public static ImageUrl: String = "https://image.mapads.net/";
  public static ExternalSystemsUrl: String = "https://systems.mapads.net/api/";
  public static GoogleUrl: String = "https://google.mapads.net/api/";
  public static FacebookUrl: String = "https://facebook.mapads.net/api/";

  public static getToken() {
    if (window.localStorage["token"] != null) {
      let myHeader = new HttpHeaders().set("Authorization", this.GetBearer());
      myHeader.set("Content-Type", "application/json");
      return {
        headers: myHeader,
      };
    }
    return null;
  }
  public static GetBearer() {
    return "Bearer " + window.localStorage["token"];
  }
}
export class LoggedUser {
  username: String;
}
