import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/services/auth/auth.guard";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
//import { LoginpageComponent } from './views/loginpage/loginpage.component';

export const rootRouterConfig: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: "./views/sessions/sessions.module#SessionsModule",
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: "./views/dashboard/dashboard.module#DashboardModule",
        data: { title: "Dashboard", breadcrumb: "DASHBOARD" },
      },
      {
        path: "customers",
        loadChildren: "./views/customers/customers.module#CustomersModule",
        data: { title: "Customers", breadcrumb: "CUSTOMERS" },
      },
      {
        path: "subscriptions",
        loadChildren:
          "./views/subscriptions/subscriptions.module#SubscriptionsModule",
        data: { title: "Subscriptions", breadcrumb: "SUBSCRIPTIONS" },
      },
      {
        path: "logs",
        loadChildren: "./views/logs/logs.module#LogsModule",
        data: { title: "Logs", breadcrumb: "LOGS" },
      },
      {
        path: "jobs",
        loadChildren: "./views/jobs/jobs.module#JobsModule",
        data: { title: "Jobs", breadcrumb: "JOBS" },
      },
      {
        path: "images",
        loadChildren: "./views/images/images.module#ImagesModule",
        data: { title: "Images", breadcrumb: "IMAGES" },
      },
      {
        path: "videos",
        loadChildren: "./views/videos/videos.module#VideosModule",
        data: { title: "Videos", breadcrumb: "VIDEOS" },
      },
      {
        path: "history",
        loadChildren: "./views/history/history.module#HistoryModule",
        data: { title: "History", breadcrumb: "HISTORY" },
      },
      {
        path: "products",
        loadChildren: "./views/products/products.module#ProductsModule",
        data: { title: "Products", breadcrumb: "PRODUCTS" },
      },
      {
        path: "stores",
        loadChildren: "./views/stores/stores.module#StoresModule",
        data: { title: "Stores", breadcrumb: "STORES" },
      },
      {
        path: "campaigns",
        loadChildren: "./views/campaigns/campaigns.module#CampaignsModule",
        data: { title: "Campaigns", breadcrumb: "CAMPAIGNS" },
      },
      {
        path: "others",
        loadChildren: "./views/others/others.module#OthersModule",
        data: { title: "Others", breadcrumb: "OTHERS" },
      },
      {
        path: "dashboard",
        loadChildren: "./views/others/others.module#OthersModule",
        data: { title: "Dashboard", breadcrumb: "DASHBOARD" },
      },

      {
        path: "promo",
        loadChildren: "./views/others/others.module#OthersModule",
        data: { title: "Promo", breadcrumb: "PROMO" },
      },
      {
        path: "mlfido",
        loadChildren: "./views/others/others.module#OthersModule",
        data: { title: "Mlfido", breadcrumb: "MLFIDO" },
      },

      {
        path: "search",
        loadChildren: "./views/search-view/search-view.module#SearchViewModule",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
