import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor() {}

  defaultMenu: IMenuItem[] = [
    {
      name: "Dashboard",
      type: "link",
      icon: "home",
      state: "dashboard",
    },
    {
      name: "Customers",
      type: "link",
      icon: "person_search",
      state: "customers",
    },
    {
      name: "Subscriptions",
      type: "dropDown",
      icon: "paid",
      state: "subscriptions",
      sub: [
        { name: "Subscriptions", state: "" },
        { name: "Payments", state: "payments" },
      ],
    },
    {
      name: "Logs",
      type: "dropDown",
      icon: "dvr",
      state: "logs",
      sub: [
        { name: "App Errors", state: "" },
        { name: "Google Ads Errors", state: "google-ads" },
        { name: "Current Errors", state: "errors" },
      ],
    },
    {
      name: "Jobs",
      type: "dropDown",
      icon: "update",
      state: "jobs",
      sub: [
        { name: "Google Jobs", state: "google-jobs" },
        { name: "Facebook Jobs", state: "fb-jobs" },
        { name: "History", state: "history" },
        { name: "Scheduled Tasks", state: "scheduled-tasks" },
      ],
    },
    {
      name: "Videos",
      type: "dropDown",
      icon: "videocam",
      state: "videos",
      sub: [{ name: "Video Render Queue", state: "video-queues" }],
    },
    {
      name: "History",
      type: "dropDown",
      icon: "manage_search",
      state: "history",
      sub: [{ name: "Email History", state: "email-history" }],
    },
    {
      name: "Products",
      type: "dropDown",
      icon: "shopping_cart",
      state: "products",
      sub: [{ name: "Newest Products", state: "newest-products" }],
    },
    {
      name: "Stores",
      type: "dropDown",
      icon: "storefront",
      state: "stores",
      sub: [{ name: "Unchecked Stores", state: "unchecked-stores" }],
    },
    {
      name: "Images",
      type: "link",
      icon: "image",
      state: "images",
    },
    {
      name: "Kampagnen",
      type: "link",
      icon: "campaigns",
      state: "campaigns",
    },
    // {
    //   name: "Monitoring", //Statistics
    //   type: "dropDown",
    //   icon: "equalizer",
    //   state: "others/dashboard",
    //   sub: [
    //     { name: "Promotions", state: "promo" }, //General
    //     // { name: 'Regional Differences', state: 'pre' },
    //     { name: "Customer Statistics", state: "sta" }, //'Product Ranking
    //     { name: "Partner Statistics", state: "stap" },
    //   ],
    // },
    // {
    //   name: "Management", //Statistics
    //   type: "dropDown",
    //   icon: "equalizer",
    //   state: "others",
    //   sub: [
    //     { name: "User Management", state: "usermanagement" }, //General
    //     { name: "Store Management", state: "storemanagement" },
    //   ],
    // },
    // {
    //   name: "Revenue Management",
    //   type: "link",
    //   icon: "monetization_on",
    //   state: "others/cashflow",
    //   tooltip: "Cash Flow",
    // },
    // {
    //   name: "AI",
    //   type: "link",
    //   tooltip: "Promo",
    //   icon: "emoji_people",
    //   state: "others/ai",
    // },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
    //     this.menuItems.next(this.separatorMenu);
    //     break;
    //   case 'icon-menu':
    //     this.menuItems.next(this.iconMenu);
    //     break;
    //   default:
    //     this.menuItems.next(this.defaultMenu);
    // }

    this.menuItems.next(this.defaultMenu);
  }
}
