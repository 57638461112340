import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "campaigns-detail-table",
  templateUrl: "./campaigns-detail-table.component.html",
  styleUrls: ["./campaigns-detail-table.component.scss"],
})
export class CampaignsDetailTableComponent implements OnInit {
  @Input() campaigns: any;
  constructor() {}

  ngOnInit() {}
}
