import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "localeDate",
})
export class LocaleDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const date = new Date(value);
    const offset = date.getTimezoneOffset() * -1;

    const momentDate = moment(value)
      .add(offset, "minutes")
      .format("DD.MM.YY HH:mm");

    return momentDate.toString();
  }
}
