import { Component, Input, OnInit } from "@angular/core";
import { ProductChannelInformation } from "app/shared/models/ProductChannelInformation";

@Component({
  selector: "app-basic-channel-card",
  templateUrl: "./basic-channel-card.component.html",
  styleUrls: ["./basic-channel-card.component.scss"],
})
export class BasicChannelCardComponent implements OnInit {
  @Input() channel: ProductChannelInformation;
  constructor() {}

  ngOnInit() {}
}
