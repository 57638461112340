import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProductChannelInformation } from "app/shared/models/ProductChannelInformation";
import { CrudService } from "app/views/others/crud.service";

@Component({
  selector: "app-promotion-successfully-created",
  templateUrl: "./promotion-successfully-created.component.html",
  styleUrls: ["./promotion-successfully-created.component.scss"],
})
export class PromotionSuccessfullyCreatedComponent implements OnInit {
  @Output() createNewPromo: EventEmitter<any> = new EventEmitter();
  @Input() promoId: number;
  isLoading: boolean;
  channels: ProductChannelInformation[];
  constructor(private crudService: CrudService) {
    this.isLoading = true;
  }

  async ngOnInit() {
    this.channels = await this.getChannels();
    this.channels = this.channels.filter((channel) => channel.isChannelEnabled);

    this.isLoading = false;
  }

  getChannels() {
    return this.crudService
      .GetProductChannelInformation(this.promoId)
      .toPromise();
  }
}
