import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { Global } from "global";

@Component({
  selector: "stores-map",
  templateUrl: "./stores-map.component.html",
  styleUrls: ["./stores-map.component.scss"],
})
export class StoresMapComponent implements OnInit {
  @ViewChild("map") mapElement: ElementRef;
  @Input() stores: any;
  public map: google.maps.Map;
  public markers: any[];
  constructor(private router: Router) {
    this.markers = [];
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const mapOptions: google.maps.MapOptions = {
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    this.setMarker();
    this.setBounds();
  }

  setMarker() {
    this.stores.forEach((store) => {
      const marker = new google.maps.Marker({
        map: this.map,
        position: new google.maps.LatLng(
          store.address.latitude,
          store.address.longitude
        ),
        title: store.storeName,
        icon: {
          url:
            Global.ImageUrl + "api/public/GetStoreMarker?id=" + store.storeId,
          scaledSize: new google.maps.Size(50, 50), // scaled size
        },
      });

      // marker.addListener("click", () => {
      //   this.router.navigate(["/mapAds/store/" + store.storeId + "/view"]);
      // });

      this.markers.push(marker);
    });
  }

  setBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.stores.forEach((store) => {
      bounds.extend(
        new google.maps.LatLng(store.address.latitude, store.address.longitude)
      );
    });

    this.map.fitBounds(bounds);
  }
}
