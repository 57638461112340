import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectMenu } from "app/shared/models/SelectMenu";

@Component({
  selector: "select-multiple-menu",
  templateUrl: "./select-multiple-menu.component.html",
  styleUrls: ["./select-multiple-menu.component.scss"],
})
export class SelectMultipleMenuComponent implements OnInit {
  @Input() menu: SelectMenu[];
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
