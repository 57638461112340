import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "campaign-details",
  templateUrl: "./campaign-details.component.html",
  styleUrls: ["./campaign-details.component.scss"],
})
export class CampaignDetailsComponent implements OnInit {
  @Input() mpCampaignId: number;
  @Input() userId: string;
  @Input() channelId: number;
  readonly CHANNEL_ID_FACEBOOK_INSTAGRAM_ADS = 1;
  readonly CHANNEL_ID_FACEBOOK_INSTAGRAM_POST = 8;
  readonly CHANNEL_ID_GOOGLE_GDN = 10;
  constructor() {}

  ngOnInit() {}
}
