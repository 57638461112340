import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "app/shared/models/User";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/others/crud.service";

@Component({
  selector: "app-search-gtin-promotion-form",
  templateUrl: "./search-gtin-promotion-form.component.html",
  styleUrls: ["./search-gtin-promotion-form.component.scss"],
})
export class SearchGtinPromotionFormComponent implements OnInit {
  @Input() user: User;
  @Output() sendProductData: EventEmitter<any> = new EventEmitter(true);
  public itemForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private crudService: CrudService,
    private loader: AppLoaderService
  ) {}

  ngOnInit() {
    this.buildItemForm();
  }

  buildItemForm() {
    this.itemForm = this.formBuilder.group({
      product_gtin: ["", Validators.required],
    });
  }

  async onSubmit() {
    if (this.itemForm.invalid) {
      return;
    }

    const productGTIN = this.itemForm.get("product_gtin").value;

    this.loader.open();
    const result = await this.crudService
      .getProductByGTIN(productGTIN, this.user.StoreId)
      .toPromise();
    this.loader.close();

    result.productGTIN_ExternalID = productGTIN;
    result.productGTIN = "";

    this.sendProductData.emit({ result });
  }
}
