import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Promo } from "../../shared/promoStruct";
import { Global } from "../../../global";
import {
  StoreMin,
  AuthModel,
  CostInterval,
} from "./user-management/UserManagementModels";
import {
  StoreAddressModel,
  VirtualStoreModel,
} from "./store-management/storemanagement-models";
import { ExternalSystem } from "app/shared/models/ExternalSystem";
import { ChannelPackage } from "app/shared/models/ChannelPackage";
import { AdPreview } from "app/shared/models/AdPreview";
import { ProductChannelInformation } from "app/shared/models/ProductChannelInformation";
import { ChildrenStoresPaginationView } from "app/shared/models/ChildrenStoresPaginationView";
import { SupportedCountry } from "app/shared/models/SupportedCountry";
import { ExternalSystemUserInformation } from "app/shared/models/ExternalSystemUserInformation";
import { ExternalSystemCategory } from "app/shared/models/ExternalSystemCategory";
import { UserAssignedPermission } from "app/shared/models/UserAssignedPermission";
import { FacebookPageDetails } from "app/shared/models/FacebookPageDetails";
import { GoogleProductStatus } from "app/shared/models/GoogleProductStatus";
import { GoogleProductError } from "app/shared/models/GoogleProductError";
import { CutoutImage } from "app/shared/models/images/CutoutImage";
import { Payment } from "app/shared/models/payments/Payment";
import { Subscription } from "app/shared/models/payments/Subscription";
import { SimpleError } from "app/shared/models/errors/SimpleError";
import { ErrorLog } from "app/shared/models/errors/ErrorLog";
import { JobLog } from "app/shared/models/jobs/JobLog";
import { JobHistory } from "app/shared/models/jobs/JobHistory";
import { ScheduledTask } from "app/shared/models/jobs/ScheduledTask";
import { VideoQueue } from "app/shared/models/videos/VideoQueue";
import { EmailHistory } from "app/shared/models/EmailHistory";
import { Product } from "app/shared/models/Product";
import { StoreDetails } from "app/shared/models/StoreDetails";
import { GroupedSubscriptions } from "app/shared/models/payments/GroupedSubscriptions";
import { store } from "@angular/core/src/render3";
import { UserVideoTemplate } from "app/shared/models/video-templates/UserVideoTemplate";
import { TemplateInformation } from "app/shared/models/video-templates/TemplateInformation";
import { CorporateColors } from "app/shared/models/CorporateColors";
import { PromoLimitStatus } from "app/shared/models/products/PromoLimitStatus";
import { ChannelSelection } from "app/shared/models/channel/ChannelSelection";
import { SubChannelSelection } from "app/shared/models/channel/SubChannelSelection";
import { TVCampaignPaginationView } from "app/shared/models/tv/TVCampaignPaginationView";
import { ChannelHistoryPaginationView } from "app/shared/models/ChannelHistoryPaginationView";
import { ChannelStats } from "app/shared/models/channel/ChannelStats";
import { SMCampaign } from "app/shared/models/marketing-portal/SMCampaign";
import { MPGDNCampaign } from "app/shared/models/marketing-portal/MPGDNCampaign";

@Injectable()
export class CrudService {
  addVirtualStore(final: VirtualStoreModel, storeId): Observable<any> {
    return this.http.post(
      Global.BackendUrl + "AddVirtualStore?userStoreId=" + storeId,
      final,
      this.getToken()
    );
  }
  deleteVirtualStore(storeId: number, userStoreId: number): Observable<any> {
    return this.http.post(
      Global.BackendUrl +
        "DeleteVirtualStore?storeId=" +
        storeId +
        "&userStoreId=" +
        userStoreId,
      null,
      this.getToken()
    );
  }
  // items: any[];
  // library: any[];
  // products: any[];

  // coordinates: any[];
  // varname: any[];

  constructor(private http: HttpClient) {
    // let userDB = new UserDB();
    // this.items = userDB.users;
    // this.products = userDB.users;
    // let userDB2 = new UserDB();
    // this.library = userDB.users;
  }

  private getToken() {
    if (window.localStorage["token"] != null) {
      let myHeader = new HttpHeaders().set(
        "Authorization",
        "Bearer " + window.localStorage["token"]
      );
      myHeader.set("Content-Type", "application/json");
      return {
        headers: myHeader,
      };
    }
    return null;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  //******* Implement your APIs ********
  getItemsByStore(id): Observable<any> {
    var items = this.http.get<Promo>(
      Global.BackendUrl + "GetPromotionFunnel?storeId=" + id,
      this.getToken()
    );
    //  varname.forEach(element => {
    //    this.items.push(element);
    //  });
    return items;
  }

  getProductsByStore(id): Observable<any> {
    var products = this.http.get<Promo>(
      Global.BackendUrl + "GetProducts?storeId=" + id,
      this.getToken()
    );
    // varname.forEach(element => {
    //   this.products.push(element);
    // });
    return products;
  }

  // //for the map long/lat
  // getStoreInformation() {
  //   return this.http.get(Global.BackendUrl+'GetMyStoreInformation',this.getToken());
  // }

  getLibraryByStore(id): Observable<any> {
    var library = this.http.get<Promo>(
      Global.BackendUrl + "GetPromotionLibrary?storeId=" + id,
      this.getToken()
    );
    // varname.forEach(element => {
    //   this.library.push(element);
    // });
    return library;
  }

  public getStoreDetails(storeId, userStoreId): Observable<any> {
    return this.http.get(
      Global.BackendUrl +
        "GetStoreDetails?storeId=" +
        storeId +
        "&userStoreId=" +
        userStoreId,
      this.getToken()
    );
    // return of({"storeId":storeId,
    // "storeDescription": "asd",
    // "storeColor": "dasd",
    // "storeName": "adad",
    // "isActive": true});
  }
  public GetCostIntervals() {
    return this.http.get<Array<CostInterval>>(
      Global.BackendUrl + "Admin/GetCostInterval",
      Global.getToken()
    );
  }
  public ResetPassword(authModel: AuthModel) {
    return this.http.post(
      Global.BackendUrl + "Account/ForcedSetPassword",
      authModel,
      this.getToken()
    );
  }
  public deleteStore(storeId, userStoreId) {
    return this.http.get(
      Global.BackendUrl +
        "DeleteStore?storeId=" +
        storeId +
        "&userStoreId=" +
        userStoreId,
      this.getToken()
    );
  }
  public updateStore(data, storeId) {
    return this.http.post(
      Global.BackendUrl + "UpdateStoreDetailsApp?userStoreId=" + storeId,
      data,
      this.getToken()
    );
  }

  public updateVirtualStore(data, userStoreId) {
    return this.http.post(
      Global.BackendUrl + "UpdateVirtualStore?userStoreId=" + userStoreId,
      data,
      this.getToken()
    );
  }

  public addStore(data, id) {
    return this.http.post(
      Global.BackendUrl + "AddStore?id=" + id,
      data,
      this.getToken()
    );
  }
  public getVirtualStores(id) {
    return this.http.get(
      Global.BackendUrl + "GetVirtualStores?storeId=" + id,
      this.getToken()
    );
  }
  public getVirtualStore(id): Observable<VirtualStoreModel> {
    return this.http.get<VirtualStoreModel>(
      Global.BackendUrl + "GetVirtualStore?storeId=" + id,
      this.getToken()
    );
  }
  public getAllChildStores(virtualStoreId) {
    return this.http.get<Array<StoreAddressModel>>(
      Global.BackendUrl + "GetAllChildStores?virtualStoreId=" + virtualStoreId,
      this.getToken()
    );
  }
  public addStoresAutomatically(data: StoreMin): Observable<any> {
    return this.http.post(
      Global.BackendUrl + "Admin/AutoAddStores",
      data,
      this.getToken()
    );
  }
  //for the map long/lat
  getLocationsByStore(id): Observable<any> {
    return this.http.get(
      Global.BackendUrl + "GetStoreCoordinates?id=" + id,
      this.getToken()
    );
  }
  getLocationCods(storeId) {
    return this.http.get(
      Global.BackendUrl + "GetStoreCoordinates?id=" + storeId,
      this.getToken()
    );
  }

  //for Tree Component
  public getTree() {
    return this.http.get(
      Global.BackendUrl +
        "Store/GetStoresClustered?storeId=" +
        window.localStorage["storeId"],
      this.getToken()
    );
  }
  public getTreeWithId(id: number) {
    return this.http.get(
      Global.BackendUrl + "Store/GetStoresClustered?storeId=" + id.toString(),
      this.getToken()
    );
  }

  public getPromotionPreview(storeId: string): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "GetStorePromotions?storeId=" + storeId,
      this.getToken()
    );
  }

  public getStoreSubCategories(catId: any, storeId = null): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "StoreSubCategories?catId=" +
        catId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public getVirtualStoresByCustomerId(id) {
    return this.http.get(
      Global.BackendUrl + "GetVirtualStores?storeId=" + id,
      this.getToken()
    );
  }
  public getChildStores(vStoreId, storeId) {
    return this.http.get(
      Global.BackendUrl +
        "GetAllChildStores?virtualStoreId=" +
        vStoreId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  // public getToken(username:string,password:string)
  // {
  //   var tokenBody:URLSearchParams = new URLSearchParams();
  //   tokenBody.set("grant_type","password");
  //   tokenBody.set("username", username);
  //   tokenBody.set("password",password);

  //   let options = {
  //     headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  //   };

  //   return this.http.post<any>(Global.BackendTokenUrl+"token",tokenBody.toString(),options)
  // }

  //NEU FÜR BACKEND VERBINDUNG

  //for product
  public getProduct() {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "StoreProduct?storeId=" +
        window.localStorage["storeId"],
      this.getToken()
    );
  }

  //for unit
  public getColors(storeId = null) {
    return this.http.get(
      Global.BackendUrl + "StoreProductColors?storeId=" + storeId,
      this.getToken()
    );
  }

  //for unit
  public getUnit() {
    return this.http.get(
      Global.BackendUrl +
        "StoreProductUnits?storeId=" +
        window.localStorage["storeId"],
      this.getToken()
    );
  }

  //for category
  public getCategory() {
    return this.http.get(
      Global.BackendUrl +
        "StoreProductCategory?storeId=" +
        window.localStorage["storeId"],
      this.getToken()
    );
  }

  public getCategories(storeId) {
    return this.http.get(
      Global.BackendUrl + "StoreProductCategory?storeId=" + storeId,
      this.getToken()
    );
  }

  public getAgeGroups(storeId = null) {
    return this.http.get(
      Global.BackendUrl + "StoreProductAgeGroup?storeId=" + storeId,
      this.getToken()
    );
  }

  public getGenders(storeId = null) {
    return this.http.get(
      Global.BackendUrl + "StoreProductGender?storeId=" + storeId,
      this.getToken()
    );
  }

  public getUnits(storeId) {
    return this.http.get(
      Global.BackendUrl + "StoreProductUnits?storeId=" + storeId,
      this.getToken()
    );
  }

  public getFashionSizes(storeId) {
    return this.http.get(
      Global.BackendUrl + "StoreProductFashionSizes?storeId=" + storeId,
      this.getToken()
    );
  }

  public getConditions(storeId): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "StoreProductConditions?storeId=" + storeId,
      this.getToken()
    );
  }

  public getEnergyEfficiencyCategories(storeId): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "StoreProductEnergyEfficiencyCategories?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public getTContracts(storeId): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "GetTContracts?storeId=" + storeId,
      this.getToken()
    );
  }

  public getShoeSizes(storeId): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "StoreProductShoeSizes?storeId=" + storeId,
      this.getToken()
    );
  }

  public setDefaultValues(defaultData, storeId) {
    return this.http.post(
      Global.BackendUrl + "User/SetDefaultValues?storeId=" + storeId,
      defaultData,
      this.getToken()
    );
  }

  //ENDE NEU FÜR BACKEND VERBINDUNG

  //START Statistics

  public getTotalClicks(storeId?, start?, end?) {
    return this.http.get(
      Global.BackendUrl +
        "stat/GetTotalClicks?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getTotalImpressions(storeId?, start?, end?) {
    return this.http.get(
      Global.BackendUrl +
        "stat/GetTotalImpressions?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getClickCounterDays(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "stat/GetClickCounterDays?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getClickCounterWeeks(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "stat/GetClickCounterWeeks?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getClickCounterMonths(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "stat/GetClickCounterMonths?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getMostPopularHours(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "stat/GetMostPopularHours?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }
  public getCategoryRanking(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "stat/GetCategoryRanking?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }
  public getPromotionRankingFirstThree(
    storeId?,
    start?,
    end?
  ): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "stat/GetPromotionRankingFirstThree?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getPromotionRanking(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl +
        "stat/GetPromotionRanking?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }
  /////////////////////////////////////////////

  public getPartnerTotalClicks(partnerId?) {
    return this.http.get(
      Global.BackendUrl + "pstat/GetTotalClicks?id=" + partnerId,
      this.getToken()
    );
  }

  public getPartnerTotalRevenue(partnerId?) {
    return this.http.get(
      Global.BackendUrl + "pstat/GetTotalRevenue?id=" + partnerId,
      this.getToken()
    );
  }

  public getPartnerCurrentMonthInformation(partnerId?): any {
    return this.http.get(
      Global.BackendUrl + "pstat/GetCurrentMonthInformation?id=" + partnerId,
      this.getToken()
    );
  }

  public getPartnerClickCounterDays(partnerId?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "pstat/GetClickCounterDays?id=" + partnerId,
      this.getToken()
    );
  }

  public getPartnerClickCounterWeeks(partnerId?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "pstat/GetClickCounterWeeks?id=" + partnerId,
      this.getToken()
    );
  }

  public getPartnerClickCounterMonths(partnerId?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "pstat/GetClickCounterMonths?id=" + partnerId,
      this.getToken()
    );
  }

  public getPartnerTypeCosts(partnerId?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "pstat/GetTypeCosts?id=" + partnerId,
      this.getToken()
    );
  }
  public getPartnerCategoryRanking(partnerId?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "pstat/GetCategoryRanking?id=" + partnerId,
      this.getToken()
    );
  }

  public GetPartnerStoreRanking(partnerId?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "pstat/GetStoreRanking?id=" + partnerId,
      this.getToken()
    );
  }

  public GetPartnerStoreRankingFirstThree(partnerId?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "pstat/GetStoreRankingFirstThree?id=" + partnerId,
      this.getToken()
    );
  }

  public GetPartnerStoreRankingNew(partnerId?): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "pstat/GetStoreRankingNew?id=" + partnerId,
      this.getToken()
    );
  }

  //////////////////////////////////////////////////////////////////////////////////

  public UploadFile(
    myFile: any,
    isTransparent: boolean = false
  ): Observable<any> {
    var myform = new FormData();
    myform.append("Upload_Image", myFile);

    //header insert
    let myHeader = new HttpHeaders().set(
      "Authorization",
      "Bearer " + window.localStorage["token"]
    );
    myHeader.set("Content-Type", "application/json");
    return this.http.post(
      Global.BackendUrl +
        "Promo/UploadPromoImage?isTransparent=" +
        isTransparent,
      myform,
      { reportProgress: true, headers: myHeader, observe: "events" }
    );
  }

  //END Statitistics
  public GetCustomerData(): Observable<any> {
    return this.http.get(
      Global.BackendUrl + "Admin/GetCustomers",
      this.getToken()
    );
  }

  public GetAllCustomers(
    limit,
    offset,
    searchString,
    order,
    orderBy,
    filters
  ): Observable<any> {
    const query = `?limit=${limit}&offset=${offset}&searchString=${searchString}&order=${order}&orderBy=${orderBy}`;

    return this.http.post(
      Global.BackendUrl + "Administration/GetAllCustomers" + query,
      filters,
      this.getToken()
    );
  }

  public GetCustomerById(userId): Observable<any> {
    return this.http.get(
      Global.BackendUrl + "Administration/GetCustomerById?userId=" + userId,
      this.getToken()
    );
  }

  public removeCustomer(storeId) {
    return this.http.post(
      Global.BackendUrl + "Admin/RemoveCustomer?storeId=" + storeId,
      null,
      this.getToken()
    );
  }

  public GetPartnerData() {
    return this.http.get(
      Global.BackendUrl + "Admin/GetPartners",
      this.getToken()
    );
  }

  addItem(item, storeId): Observable<any> {
    return this.http.post(
      Global.BackendUrl + "Promo?storeId=" + storeId,
      item,
      this.getToken()
      //  {
      //   headers:new HttpHeaders()
      //   .set('Content-Type','application/json')
      //   }
    );

    //  return this.http.get("http://141.99.153.112:45455/api/GetStoreProducts").pipe(delay(1000));
  }

  updateItem(id, item, storeId) {
    return this.http.post(
      Global.BackendUrl + "Promo/Modify?storeId=" + storeId,
      item,
      this.getToken()
    );
  }

  deactivateItem(row, storeId) {
    return this.http.post(
      Global.BackendUrl + "Promo/Deactivate?storeId=" + storeId,
      row,
      this.getToken()
    );
  }

  removeItem(row, storeId) {
    return this.http.post(
      Global.BackendUrl + "Promo/Delete?storeId=" + storeId,
      row,
      this.getToken()
      // {
      //  headers:new HttpHeaders()
      //  .set('Content-Type','application/json')
      //  }
    );
    //  .subscribe((data)=>{
    //console.log(data);
    // var temp = this.http.get("http://141.99.153.112:45455/api/GetStoreProducts").subscribe(resp=>{

    // data = resp;
    // console.log(resp);
    // });
    // console.log(data);

    //return temp;
    // });

    // console.log(req);
    //while(temp==null);

    //return this.http.get("http://141.99.153.112:45455/api/GetStoreProducts")
  }

  getPromoLimitStatus(storeId) {
    return this.http.get<PromoLimitStatus>(
      Global.BackendUrl + "/GetPromoLimitStatus?storeId=" + storeId,
      this.getToken()
    );
  }

  public changeHighlighting(promoId, highlightDate, storeId) {
    return this.http.get(
      Global.BackendUrl +
        `Promo/ChangeHighlighting?promoId=${promoId}&highlightDate=${highlightDate}&storeId=${storeId}`,
      this.getToken()
    );
  }

  public getStoreTree(storeId, userStoreId) {
    return this.http.get(
      Global.BackendUrl +
        "Store/GetStoreTree?storeId=" +
        storeId +
        "&userStoreId=" +
        userStoreId,
      this.getToken()
    );
  }
  public getBase64ImageFromUrl(url) {
    return this.http.get(url);
  }
  public addProduct(data) {
    return this.http.post(
      Global.BackendUrl +
        "Product/Create?storeId=" +
        window.localStorage["storeId"],
      data,
      this.getToken()
    );
  }
  public modifyProduct(data) {
    return this.http.post(
      Global.BackendUrl +
        "Product/Modify?storeId=" +
        window.localStorage["storeId"],
      data,
      this.getToken()
    );
  }

  removeProduct(row) {
    return this.http.post(
      Global.BackendUrl +
        "Product/Delete?storeId=" +
        window.localStorage["storeId"],
      row,
      this.getToken()
    );
  }

  public getAllExternalSystems() {
    return this.http.get<ExternalSystem[]>(
      Global.ExternalSystemsUrl + "UserExternalServices/GetAllExternalSystems",
      this.getToken()
    );
  }

  public getChannelPackages(): Observable<ChannelPackage[]> {
    return this.http.get<ChannelPackage[]>(
      Global.BackendUrl + "Channel/GetChannelPackages",
      this.getToken()
    );
  }

  public resetAssistent(storeId) {
    return this.http.get(
      Global.BackendUrl + "Admin/ResetAssistent?storeId=" + storeId,
      this.getToken()
    );
  }

  public getDefaultValues(storeId): Observable<any> {
    return this.http.get<any[]>(
      Global.BackendUrl + "User/GetDefaultValues?storeId=" + storeId,
      this.getToken()
    );
  }

  public getBackgroundRemovalTries() {
    return this.http.get<any>(
      Global.BackendUrl + "GetBackgroundRemovalTries",
      this.getToken()
    );
  }

  public removeImageBackground(
    imageData: any,
    checkTransparency: boolean = false
  ) {
    const form = new FormData();
    form.append("Upload_Image", imageData);

    //header insert
    const header = new HttpHeaders().set(
      "Authorization",
      "Bearer " + window.localStorage["token"]
    );
    header.set("Content-Type", "application/json");
    return this.http.post(
      Global.BackendUrl +
        "RemoveImageBackground?checkTransparency=" +
        checkTransparency,
      form,
      {
        reportProgress: true,
        headers: header,
        observe: "events",
        responseType: "blob",
      }
    );
  }

  uploadStoreImage(form): Observable<any> {
    let myHeader = new HttpHeaders();
    myHeader.set("Content-Type", "application/json");

    return this.http.post(Global.BackendUrl + "Store/UploadStoreImage", form, {
      reportProgress: true,
      headers: myHeader,
      observe: "events",
    });
  }

  public getAllVideoTemplates(mobile: boolean = false, storeId) {
    return this.http.get<any>(
      Global.BackendUrl +
        "User/GetAllVideoTemplates?mobile=" +
        mobile +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public getVideoStoreMapping(mobile: boolean = false, storeId) {
    return this.http.get<any>(
      Global.BackendUrl +
        "User/GetVideoStoreMapping?mobile=" +
        mobile +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public setVideoStoreMapping(data: any, mobile: boolean = false, storeId) {
    return this.http.post(
      Global.BackendUrl +
        "User/SetVideoStoreMapping?mobile=" +
        mobile +
        "&storeId=" +
        storeId,
      data,
      this.getToken()
    );
  }

  public getAllVideoTemplatesWithThemes(storeId: number) {
    let url = Global.BackendUrl + "User/GetAllVideoTemplatesWithThemes";

    if (storeId) {
      url += "?storeId=" + storeId;
    }

    return this.http.get<UserVideoTemplate[]>(url, this.getToken());
  }

  public getStoreCorporateColor(virtualStoreId: number, storeId?: number) {
    let url =
      Global.BackendUrl +
      `User/GetStoreCorporateColor?virtualStoreId=${virtualStoreId}`;

    if (storeId) {
      url += "&storeId=" + storeId;
    }

    return this.http.get<any>(url, this.getToken());
  }

  public getTemplateInformationForAllStores(
    virtualStoreId: number,
    storeId: number
  ) {
    let url =
      Global.BackendUrl +
      `User/GetTemplateInformationForAllStores?virtualStoreId=${virtualStoreId}&storeId=${storeId}`;

    return this.http.get<TemplateInformation>(url, this.getToken());
  }

  public createDemoVideoAd(username, storeId) {
    return this.http.get(
      Global.BackendUrl +
        "GoogleAds/CreateDemoVideoAd?username=" +
        username +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public sendVideoToRenderserver(url) {
    return this.http.get(url, this.getToken());
  }

  public setStoreCorporateColors(
    corporateColors: CorporateColors,
    virtualStoreId: number,
    storeId?: number
  ) {
    let url =
      Global.BackendUrl +
      `User/SetStoreCorporateColor?virtualStoreId=${virtualStoreId}`;

    if (storeId) {
      url += "&storeId=" + storeId;
    }

    return this.http.post<any>(url, corporateColors, this.getToken());
  }

  public setTemplateInformationForAllStores(
    rootStoreId: number,
    storeId: number,
    data: TemplateInformation[]
  ) {
    let url =
      Global.BackendUrl +
      `User/SetTemplateInformationForAllStores?rootStoreId=${rootStoreId}&storeId=` +
      storeId;

    return this.http.post<TemplateInformation>(url, data, this.getToken());
  }

  public setTemplateInformation(
    virtualStoreId: number,
    storeId: number,
    templateInformation: TemplateInformation
  ) {
    let url =
      Global.BackendUrl +
      `User/SetTemplateInformation?virtualStoreId=${virtualStoreId}&storeId=` +
      storeId;

    return this.http.post<TemplateInformation>(
      url,
      templateInformation,
      this.getToken()
    );
  }

  getProductPagination(limit, offset, searchString, storeId): Observable<any> {
    const query = `?storeId=${storeId}&limit=${limit}&offset=${offset}&searchText=${searchString}`;
    return this.http.get<Promo>(
      Global.BackendUrl + "StoreProductPagination" + query,
      this.getToken()
    );
  }

  public getAllProducts(
    limit,
    offset,
    type,
    searchString,
    order,
    orderBy,
    storeId
  ): Observable<any> {
    const query = `?limit=${limit}&offset=${offset}&type=${type}&searchString=${searchString}&order=${order}&orderBy=${orderBy}&storeId=${storeId}`;
    return this.http.get<Promo>(
      Global.BackendUrl + "GetAllPromotions" + query,
      this.getToken()
    );
  }

  getProductByGTIN(gtin, storeId): Observable<any> {
    return this.http.get<Promo>(
      Global.BackendUrl +
        "GetProductByGTIN?eannumber=" +
        gtin +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  getProductById(promoId: number, storeId): Observable<any> {
    return this.http.get<Promo>(
      Global.BackendUrl +
        "Promo/Get?promoId=" +
        promoId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  GetFacebookAdPreviews(
    AdId: string,
    storeId: number
  ): Observable<AdPreview[]> {
    return this.http.get<AdPreview[]>(
      Global.FacebookUrl +
        "FacebookConnectivity/AdPreview?adId=" +
        AdId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetAccounts(storeId: number) {
    return this.http.get<any>(
      Global.BackendUrl + "Channel/GetAccounts?storeId=" + storeId,
      this.getToken()
    );
  }

  public GetProductChannelInformation(promoId: number) {
    return this.http.get<ProductChannelInformation[]>(
      Global.BackendUrl +
        `Channel/GetProductChannelInformation?promoId=${promoId}`,
      this.getToken()
    );
  }

  public getPublishedCampaigns(page: number, itemsPerPage: number) {
    return this.http.get<any>(
      Global.BackendUrl +
        `Administration/GetPublishedCampaigns?page=${page}&itemsPerPage=${itemsPerPage}`,
      this.getToken()
    );
  }

  public getActiveCampaigns(page: number, itemsPerPage: number) {
    return this.http.get<any>(
      Global.BackendUrl +
        `Administration/GetActiveCampaigns?page=${page}&itemsPerPage=${itemsPerPage}`,
      this.getToken()
    );
  }

  public getSocialMediaCampaignByMPCampaign(
    mpCampaignId: number,
    userId: string
  ) {
    return this.http.get<SMCampaign>(
      Global.BackendUrl +
        "MarketingPortal/getSocialMediaCampaignByMPCampaign?mpCampaignId=" +
        mpCampaignId +
        "&userId=" +
        userId,
      this.getToken()
    );
  }

  public getGoogleDisplayCampaignByMPCampaign(
    mpCampaignId: number,
    userId: string
  ) {
    return this.http.get<MPGDNCampaign>(
      Global.BackendUrl +
        "MarketingPortal/getGoogleDisplayCampaignByMPCampaign?mpCampaignId=" +
        mpCampaignId +
        "&userId=" +
        userId,
      this.getToken()
    );
  }

  public approveCampaign(mpCampaignId: number, isApproved: boolean) {
    return this.http.get<MPGDNCampaign>(
      Global.BackendUrl +
        "Administration/ApproveCampaign?campaignId=" +
        mpCampaignId +
        "&isApproved=" +
        isApproved,
      this.getToken()
    );
  }

  public getAllLanguages() {
    return this.http.get<any>(
      Global.BackendUrl + `Administration/GetAllLanguages`,
      this.getToken()
    );
  }

  public updateLangFromUser(userId, languageId) {
    return this.http.get<any>(
      Global.BackendUrl +
        `Administration/UpdateLangFromUser?userId=${userId}&langId=${languageId}`,
      this.getToken()
    );
  }

  public getAllFacebookStatus() {
    return this.http.get<any>(
      Global.BackendUrl + "Administration/GetAllFacebookStatus",
      this.getToken()
    );
  }

  public getAllGoogleStatus() {
    return this.http.get<any>(
      Global.BackendUrl + "Administration/GetAllGoogleStatus",
      this.getToken()
    );
  }

  public getChildrenStoresWithFilter(
    vStoreId: number,
    limit: number,
    offset: number,
    searchString: string,
    order: string,
    orderBy: number,
    userStoreId: number
  ) {
    const query = `?storeId=${userStoreId}&limit=${limit}&offset=${offset}&virtualStoreId=${vStoreId}&searchString=${searchString}&order=${order}&orderBy=${orderBy}`;
    return this.http.get<ChildrenStoresPaginationView>(
      Global.BackendUrl + "GetChildrenStoresWithFilter" + query,
      this.getToken()
    );
  }

  public getSupportedCountries(storeId) {
    return this.http.get<SupportedCountry[]>(
      Global.BackendUrl + "GetCountries?storeId=" + storeId
      // this.getToken()
    );
  }

  getStoreCoordinatesFromAddress(data, storeId): Observable<any> {
    return this.http.post(
      Global.BackendUrl + "GetStoreCoordinatesFromAddress?storeId=" + storeId,
      data,
      this.getToken()
    );
  }

  addStoreِApp(storeData, storeId) {
    return this.http.post(
      Global.BackendUrl + "AddStoreApp?storeId=" + storeId,
      storeData,
      this.getToken()
    );
  }

  getVirtualStoresWithFilter(
    storeId: number,
    offset: number,
    limit: number,
    searchString: string,
    order: string,
    orderBy: number
  ) {
    const query = `?storeId=${storeId}&limit=${limit}&offset=${offset}&searchString=${searchString}&order=${order}&orderBy=${orderBy}`;
    return this.http.get<ChildrenStoresPaginationView>(
      Global.BackendUrl + "GetVirtualStoresWithFilter" + query,
      this.getToken()
    );
  }

  public getStoreTreeById(storeId: number, userStoreId) {
    return this.http.get(
      Global.BackendUrl +
        "Store/GetStoreTree?storeId=" +
        storeId +
        "&userStoreId=" +
        userStoreId,
      this.getToken()
    );
  }
  public getChannelHistory(
    storeId: number,
    startDate,
    endDate,
    channelId,
    activity,
    offset,
    limit,
    searchText,
    order,
    orderBy
  ) {
    const query = `?storeId=${storeId}&startDate=${startDate}&endDate=${endDate}&channelId=${channelId}&activity=${activity}&offset=${offset}&limit=${limit}&searchText=${searchText}&order=${order}&orderBy=${orderBy}`;
    return this.http.get<ChannelHistoryPaginationView>(
      Global.BackendUrl + "Channel/GetChannelHistory" + query,
      this.getToken()
    );
  }

  public getChannels(storeId) {
    return this.http.get<ChannelStats>(
      Global.BackendUrl + "Channel/GetChannels?storeId=" + storeId,
      this.getToken()
    );
  }

  public GetAllExternalSystems(storeId) {
    return this.http.get<any>(
      Global.ExternalSystemsUrl +
        "UserExternalServices/GetAllExternalSystems?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetExternalSystemInformation(
    ExternalSystemId: number,
    storeId: number
  ) {
    return this.http.get<any>(
      Global.BackendUrl +
        "User/GetExternalSystemInformation?ExternalSystemId=" +
        ExternalSystemId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetExternalSystem(ExternalSystemId: number, storeId: number) {
    return this.http.get<any>(
      Global.ExternalSystemsUrl +
        "UserExternalServices/GetExternalSystem?ExternalSystemId=" +
        ExternalSystemId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public LinkUserExternalSystemInformation(
    data: ExternalSystemUserInformation,
    storeId: number
  ) {
    return this.http.post(
      Global.ExternalSystemsUrl +
        "UserExternalServices/LinkUserExternalSystemInformation?storeId=" +
        storeId,
      data,
      this.getToken()
    );
  }

  public GetShopifyApplicationApi(storeId) {
    return this.http.get<any>(
      Global.ExternalSystemsUrl +
        "ShopifyConnection/GetApiKey?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetShopifyRandomNonce(shopName: string, storeId: number) {
    return this.http.get<any>(
      Global.ExternalSystemsUrl +
        "ShopifyConnection/GenerateNonceForUser?shopId=" +
        shopName +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetExternalSystemSelectedCategories(
    ExternalSystemId: number,
    storeId
  ) {
    return this.http.get<string[]>(
      Global.ExternalSystemsUrl +
        "UserExternalServices/GetUserCategories?externalSystemId=" +
        ExternalSystemId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetExternalSystemSelectedTags(ExternalSystemId: number, storeId) {
    return this.http.get<string[]>(
      Global.ExternalSystemsUrl +
        "UserExternalServices/GetUserTags?externalSystemId=" +
        ExternalSystemId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetExternalSystemAllCategories(ExternalSystemId: number, storeId) {
    return this.http.get<ExternalSystemCategory[]>(
      Global.ExternalSystemsUrl +
        "UserExternalServices/GetAllCategories?externalSystemId=" +
        ExternalSystemId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetExternalSystemAllTags(ExternalSystemId: number, storeId) {
    return this.http.get<ExternalSystemCategory[]>(
      Global.ExternalSystemsUrl +
        "UserExternalServices/GetAllTags?externalSystemId=" +
        ExternalSystemId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public UpdateExternalSystemSelectedCategories(
    ExternalSystemId: number,
    updatedCats: ExternalSystemCategory[],
    storeId
  ) {
    return this.http.post(
      Global.ExternalSystemsUrl +
        "UserExternalServices/UpdateCategories?externalSystemId=" +
        ExternalSystemId +
        "&storeId=" +
        storeId,
      updatedCats,
      this.getToken()
    );
  }

  public UpdateExternalSystemSelectedTags(
    ExternalSystemId: number,
    updatedTags: ExternalSystemCategory[],
    storeId
  ) {
    return this.http.post(
      Global.ExternalSystemsUrl +
        "UserExternalServices/UpdateTags?externalSystemId=" +
        ExternalSystemId +
        "&storeId=" +
        storeId,
      updatedTags,
      this.getToken()
    );
  }

  public RemoveUserExternalSystemInformation(
    data: ExternalSystemUserInformation,
    storeId: number
  ) {
    return this.http.post(
      Global.ExternalSystemsUrl +
        "UserExternalServices/RemoveUserExternalSystemInformation?storeId=" +
        storeId,
      data,
      this.getToken()
    );
  }

  public getTotalStatistics(storeId) {
    return this.http.get<any>(
      Global.BackendUrl + "Channel/GetTotalStatistics?storeId=" + storeId,
      this.getToken()
    );
  }

  public getWeeklyProductStatistics(
    limit,
    offset,
    order,
    orderBy,
    searchString,
    storeId
  ) {
    const query = `?limit=${limit}&offset=${offset}&order=${order}&orderBy=${orderBy}&searchString=${searchString}&storeId=${storeId}`;
    return this.http.get<any>(
      Global.BackendUrl + "Channel/GetWeeklyProductStatistics" + query,
      this.getToken()
    );
  }

  public getWeeklyStatisticsSummary(type: number, storeId: number) {
    return this.http.get<any>(
      Global.BackendUrl +
        "Channel/GetWeeklyStatisticsSummary?type=" +
        type +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public getAgeRangeStatistics(storeId) {
    return this.http.get<any>(
      Global.BackendUrl + "Channel/GetAgeRangeStatistics?storeId=" + storeId,
      this.getToken()
    );
  }

  public getGenderStatistics(storeId) {
    return this.http.get<any>(
      Global.BackendUrl + "Channel/GetGenderStatistics?storeId=" + storeId,
      this.getToken()
    );
  }

  public getSearchKeyStatistics(storeId) {
    return this.http.get<any>(
      Global.BackendUrl + "Channel/GetSearchKeysStatistics?storeId=" + storeId,
      this.getToken()
    );
  }

  public getLocationsStatistics(storeId) {
    return this.http.get<any>(
      Global.BackendUrl + "Channel/GetLocationsStatistics?storeId=" + storeId,
      this.getToken()
    );
  }

  public GetGoogleAccount(storeId: number) {
    return this.http.get<any>(
      Global.BackendUrl + "Channel/GetGoogleAccount?storeId=" + storeId,
      this.getToken()
    );
  }

  public GetAdvancedGoogleAccountDetails(storeId: number) {
    return this.http.get<any>(
      Global.GoogleUrl +
        "GoogleAdmin/GetAdvancedGoogleAccountDetails?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public GetFacebookAccount(storeId: number) {
    return this.http.get<any>(
      Global.FacebookUrl +
        "FacebookChannel/GetFacebookAccount?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public getUserAssignedPermissions(storeId: number) {
    return this.http.get<UserAssignedPermission[]>(
      Global.FacebookUrl +
        "FacebookConnectivity/GetUserAssignedPermissions?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public createFacebookUserAds(username: string) {
    return this.http.get<any>(
      Global.FacebookUrl + "facebookJobs/CreateUserAds?username=" + username,
      this.getToken()
    );
  }

  public createFacebookUserVideoAds(username: string, triggerDate: string) {
    return this.http.get<any>(
      Global.FacebookUrl +
        "facebookJobs/FacebookCreateUserVideoAds?username=" +
        username +
        "&triggerDate=" +
        triggerDate,
      this.getToken()
    );
  }

  public getAllFacebookPageDetails(storeId: number) {
    return this.http.get<FacebookPageDetails[]>(
      Global.FacebookUrl +
        "FacebookConnectivity/GetAllFacebookPageDetails?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public linkFacebookPage(pageId: string, storeId: number) {
    return this.http.get<any>(
      Global.FacebookUrl +
        "facebookAds/LinkPage?pageId=" +
        pageId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public createFacebookPagePosts(pageId: number, storeId: number) {
    return this.http.get<any>(
      Global.FacebookUrl +
        "facebookJobs/PagePosts?fp_id=" +
        pageId +
        "&storeId=" +
        storeId,
      this.getToken()
    );
  }

  public changeFacebookPostingFrequencyForPage(
    pageId: number,
    storeId: number,
    amount: number
  ) {
    return this.http.get<any>(
      Global.FacebookUrl +
        "FacebookConnectivity/ChangePostingFrequencyForPage?pageId=" +
        pageId +
        "&storeId=" +
        storeId +
        "&amount=" +
        amount,
      this.getToken()
    );
  }

  public getGoogleProductStatus(storeId: number) {
    return this.http.get<GoogleProductStatus[]>(
      Global.GoogleUrl + "Google/GetProductStatus?storeId=" + storeId,
      this.getToken()
    );
  }

  public updateGoogleMyBusiness(
    googleMyBusinessEmail: string,
    storeId: number
  ) {
    return this.http.get<any>(
      Global.GoogleUrl +
        "Google/UpdateGoogleMyBusiness?storeId=" +
        storeId +
        "&googleMyBusinessEmail=" +
        googleMyBusinessEmail,
      this.getToken()
    );
  }

  public createGoogleVideoAds(username: string, storeId: number) {
    return this.http.get<any>(
      Global.BackendUrl +
        "GoogleAds/CreateVideoAd?storeId=" +
        storeId +
        "&username=" +
        username,
      this.getToken()
    );
  }

  public changeGoogleUserStatus(isActive: boolean, storeId: number) {
    return this.http.get<any>(
      Global.GoogleUrl +
        "Google/changeGoogleUserStatus?storeId=" +
        storeId +
        "&isActive=" +
        isActive,
      this.getToken()
    );
  }

  public requestInventoryVerification(storeId: number) {
    return this.http.get<any>(
      Global.GoogleUrl +
        "Google/RequestInventoryVerification?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public updateInventory(storeId: number) {
    return this.http.get<any>(
      Global.GoogleUrl + "Google/UpdateInventory?storeId=" + storeId,
      this.getToken()
    );
  }

  public updateProductsBatchWithPOS(storeId: number) {
    return this.http.get<any>(
      Global.GoogleUrl + "Google/UpdateProductsBatch?storeId=" + storeId,
      this.getToken()
    );
  }

  public getGoogleProductErrors(storeId: number) {
    return this.http.get<GoogleProductError[]>(
      Global.GoogleUrl +
        "GoogleAdmin/getGoogleProductErrors?storeId=" +
        storeId,
      this.getToken()
    );
  }

  public createOrUpdateGoogleAccount(storeId: number) {
    return this.http.get<GoogleProductError[]>(
      Global.GoogleUrl + "Google/CreateOrUpdateAccount?storeId=" + storeId,
      this.getToken()
    );
  }

  public getOriginalAndCutoutImage(limit: number, offset: number) {
    return this.http.get<CutoutImage>(
      Global.BackendUrl +
        "Administration/CompareImages?page=" +
        offset +
        "&entries=" +
        limit,
      this.getToken()
    );
  }

  public approveImage(imageMetaId) {
    return this.http.get<any>(
      Global.BackendUrl +
        "Administration/ApproveImage?imageMetaId=" +
        imageMetaId,
      this.getToken()
    );
  }

  public rejectImage(imageMetaId) {
    return this.http.get<any>(
      Global.BackendUrl +
        "Administration/RejectImage?imageMetaId=" +
        imageMetaId,
      this.getToken()
    );
  }

  public getAllCanceledPayments() {
    return this.http.get<Payment[]>(
      Global.BackendUrl + "Administration/GetAllCanceledPayments",
      this.getToken()
    );
  }

  public getCurrentPayments() {
    return this.http.get<Payment[]>(
      Global.BackendUrl + "Administration/GetCurrentPayments",
      this.getToken()
    );
  }

  public getAllPayments() {
    return this.http.get<Payment[]>(
      Global.BackendUrl + "Administration/GetAllPayments",
      this.getToken()
    );
  }

  public GetBudget(storeId) {
    return this.http.get<any>(
      Global.BackendUrl + "User/GetBudget?storeId=" + storeId,
      this.getToken()
    );
  }

  public getAllTvCampaigns(
    page: number,
    itemsPerPage: number,
    storeId: number
  ) {
    return this.http.get<TVCampaignPaginationView>(
      Global.BackendUrl +
        `stat/GetAllTvCampaigns?page=${page}&itemsPerPage=${itemsPerPage}&storeId=${storeId}`,
      this.getToken()
    );
  }

  public getAllTvCampaignImpressionsPerChannel(
    data: object = {},
    storeId: number
  ) {
    return this.http.post(
      Global.BackendUrl +
        "stat/GetAllTvCampaignImpressionsPerChannel?storeId=" +
        storeId,
      data,
      this.getToken()
    );
  }

  public getTvCampaignImpressionsPerChannel(
    campaignName: string,
    data: object = {},
    storeId: number
  ) {
    var encodedStr = encodeURIComponent(campaignName)
      .replace("(", "%28")
      .replace(")", "%29");

    return this.http.post(
      Global.BackendUrl +
        `stat/GetTvCampaignImpressionsPerChannel?campaignName=${encodedStr}&storeId=${storeId}`,
      data,
      this.getToken()
    );
  }

  public getTVCampaignImages(campaignName: string, storeId: number) {
    var encodedStr = encodeURIComponent(campaignName)
      .replace("(", "%28")
      .replace(")", "%29");

    return this.http.get<any>(
      Global.BackendUrl +
        `stat/GetTVCampaignImages?campaignName=${encodedStr}&storeId=${storeId}`,
      this.getToken()
    );
  }

  public getTvCampaign(campaignName: string, storeId: number) {
    var encodedStr = encodeURIComponent(campaignName)
      .replace("(", "%28")
      .replace(")", "%29");
    return this.http.get<any>(
      Global.BackendUrl +
        `stat/GetTvCampaign?campaignName=${encodedStr}&storeId=${storeId}`,
      this.getToken()
    );
  }

  public getSingleCampaignGroupedTVImpressions(
    campaignName: string,
    type: number = 0,
    storeId: number
  ) {
    var encodedStr = encodeURIComponent(campaignName)
      .replace("(", "%28")
      .replace(")", "%29");

    return this.http.get<any>(
      Global.BackendUrl +
        `stat/getSingleCampaignGroupedTVImpressions?campaignName=${encodedStr}&type=${type}&storeId=${storeId}`,
      this.getToken()
    );
  }

  public setNewBudget(
    userName,
    newBudget,
    totalBudget,
    startDate,
    endDate,
    comment,
    considerMinBudget
  ) {
    return this.http.get(
      Global.BackendUrl +
        `Administration/setNewBudget?userName=${userName}&newBudget=${newBudget}&totalBudget=${totalBudget}&startDate=${startDate}&endDate=${endDate}&comment=${comment}&considerMinBudget=${considerMinBudget}`,
      this.getToken()
    );
  }

  public updateBudgetAmount(
    aub_id,
    totalBudget,
    newBudget,
    considerMinBudget = true
  ) {
    return this.http.get(
      Global.BackendUrl +
        `Administration/updateBudgetAmount?aub_id=${aub_id}&newBudget=${newBudget}&totalBudget=${totalBudget}&considerMinBudget=${considerMinBudget}`,
      this.getToken()
    );
  }

  public splitBudgetAutomatically(aub_id, considerMinBudget = true) {
    return this.http.get(
      Global.BackendUrl +
        `Administration/splitBudgetAutomatically?aub_id=${aub_id}&considerMinBudget=${considerMinBudget}`,
      this.getToken()
    );
  }

  public splitBudgetManually(
    aub_id,
    newLiaBudget,
    newFBBudget,
    newVideoBudget
  ) {
    return this.http.get(
      Global.BackendUrl +
        `Administration/splitBudgetManually?aub_id=${aub_id}&newLiaBudget=${newLiaBudget}&newFBBudget=${newFBBudget}&newVideoBudget=${newVideoBudget}`,
      this.getToken()
    );
  }

  public deleteBudget(aub_id) {
    return this.http.get(
      Global.BackendUrl + `Administration/DeleteBudget?aub_id=${aub_id}`,
      this.getToken()
    );
  }

  public getAllSubcriptions() {
    return this.http.get<GroupedSubscriptions[]>(
      Global.BackendUrl + "Administration/GetAllSubcriptions",
      this.getToken()
    );
  }

  public getAppErrors() {
    return this.http.get<SimpleError[]>(
      Global.BackendUrl + "Administration/GetAppErrors",
      this.getToken()
    );
  }

  public getGoogleAdsErrors() {
    return this.http.get<SimpleError[]>(
      Global.BackendUrl + "Administration/GetGoogleAdsErrors",
      this.getToken()
    );
  }

  public getCurrentErrorLog() {
    return this.http.get<ErrorLog[]>(
      Global.BackendUrl + "Administration/GetCurrentErrorLog",
      this.getToken()
    );
  }

  public getGoogleJobs(active) {
    return this.http.get<JobLog[]>(
      Global.BackendUrl + "Administration/GetGoogleJobs?active=" + active,
      this.getToken()
    );
  }

  public skipGoogleJob(googleJobId) {
    return this.http.get(
      Global.BackendUrl +
        "Administration/SkipGoogleJob?googleJobId =" +
        googleJobId,
      this.getToken()
    );
  }

  public includeGoogleJob(googleJobId) {
    return this.http.get(
      Global.BackendUrl +
        "Administration/IncludeGoogleJob?googleJobId=" +
        googleJobId,
      this.getToken()
    );
  }

  public getFBJobs(active) {
    return this.http.get<JobLog[]>(
      Global.BackendUrl + "Administration/GetFBJobs?active=" + active,
      this.getToken()
    );
  }

  public skipFBJob(fbJobId) {
    return this.http.get(
      Global.BackendUrl + "Administration/SkipFBJob?fbJobId=" + fbJobId,
      this.getToken()
    );
  }

  public includeFBJob(fbJobId) {
    return this.http.get(
      Global.BackendUrl + "Administration/IncludeFBJob?fbJobId=" + fbJobId,
      this.getToken()
    );
  }

  public getJobHistory() {
    return this.http.get<JobHistory[]>(
      Global.BackendUrl + "Administration/GetJobHistory",
      this.getToken()
    );
  }

  public cancelScheduledJob(service) {
    return this.http.get(
      Global.BackendUrl + "ScheduledJob/Canceljob?CanceledJob=" + service,
      this.getToken()
    );
  }

  public getScheduledTasks() {
    return this.http.get<ScheduledTask[]>(
      Global.BackendUrl + "Administration/GetScheduledTasks",
      this.getToken()
    );
  }

  public resetScheduledTask(taskId) {
    return this.http.get(
      Global.BackendUrl + "Administration/ResetScheduledTask?taskId=" + taskId,
      this.getToken()
    );
  }

  public toggleScheduledTask(scheduledTaskId) {
    return this.http.get(
      Global.BackendUrl +
        "Administration/ToggleScheduledTask?scheduledTaskId=" +
        scheduledTaskId,
      this.getToken()
    );
  }

  public getGoogleVideoQueue() {
    return this.http.get<VideoQueue[]>(
      Global.BackendUrl + "Administration/GetGoogleVideoQueue",
      this.getToken()
    );
  }

  public getFBVideoQueue() {
    return this.http.get<VideoQueue[]>(
      Global.BackendUrl + "Administration/GetFBVideoQueue",
      this.getToken()
    );
  }

  public getAllVideoQueueData() {
    return this.http.get<VideoQueue[]>(
      Global.BackendUrl + "Administration/GetAllVideoQueueData",
      this.getToken()
    );
  }

  public getEmailHistory() {
    return this.http.get<EmailHistory[]>(
      Global.BackendUrl + "Administration/GetEmailHistory",
      this.getToken()
    );
  }

  public getNewestProds(limit: number) {
    return this.http.get<Product[]>(
      Global.BackendUrl + "Administration/GetNewestProds?limit=" + limit,
      this.getToken()
    );
  }

  public getUncheckedStores() {
    return this.http.get<StoreDetails[]>(
      Global.BackendUrl + "Administration/GetUncheckedStores",
      this.getToken()
    );
  }

  public setStoreChecked(storeId: number) {
    return this.http.get(
      Global.BackendUrl + "Administration/setUncheckedStore?StoreId=" + storeId,
      this.getToken()
    );
  }

  public getDashboardData(entries) {
    return this.http.get<any>(
      Global.BackendUrl + "Administration/GetDashboardData?entries=" + entries,
      this.getToken()
    );
  }

  getChannelSelection(storeId: number) {
    return this.http.get<ChannelSelection>(
      Global.BackendUrl + "Channel/GetChannelPreferences?storeId=" + storeId,
      this.getToken()
    );
  }

  getSubChannelSelection(storeId: number) {
    return this.http.get<SubChannelSelection>(
      Global.BackendUrl + "Channel/GetSubChannelPreferences?storeId=" + storeId,
      this.getToken()
    );
  }

  getQuestionnaireContent(storeId: number) {
    return this.http.get<any>(
      Global.BackendUrl + "User/GetQuestionnaireContent?storeId=" + storeId,
      this.getToken()
    );
  }
}
