import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ExternalSystem } from "app/shared/models/ExternalSystem";
@Component({
  selector: "app-external-system-card",
  templateUrl: "./external-system-card.component.html",
  styleUrls: ["./external-system-card.component.scss"],
})
export class ExternalSystemCardComponent implements OnInit {
  @Input() externalSystem: ExternalSystem;
  @Output() onClickExternalSystem: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
