import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { MAX_UPLOAD_SIZE } from "../../../../constants";
import { UploadImageDialogComponent } from "../dialogs/upload-image-dialog/upload-image-dialog.component";

@Component({
  selector: "app-drag-drop-upload",
  templateUrl: "./drag-drop-upload.component.html",
  styleUrls: ["./drag-drop-upload.component.scss"],
})
export class DragDropUploadComponent implements OnInit {
  constructor(
    private snack: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}
  files: string[] = [];
  @Input() removeBackground?: boolean = false;
  @Output() filesDropped = new EventEmitter<string[]>();
  @Output() filesDroppedEvent = new EventEmitter<any>();

  showDeleteButtons: boolean = false;
  showFilesUploaded: boolean = false;
  ngOnInit() {}

  emitFiles(event, isDragEvent) {
    const files = isDragEvent ? event : event.target.files;
    let error = false;

    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      const fileSize = Number((element.size / 1024 / 1024).toFixed(4));

      if (fileSize > MAX_UPLOAD_SIZE) {
        error = true;
        this.snack.open(
          this.translate.instant("ImageUploadMaxSizeError", {
            maxUploadSize: MAX_UPLOAD_SIZE,
          }),
          "OK",
          {
            duration: 4000,
          }
        );
        break;
      } else {
        const dialogRef = this.dialog.open(UploadImageDialogComponent, {
          width: "1000px",
          maxHeight: "95vh",
          data: {
            file: element,
            useBackgroundRemover: this.removeBackground,
            useImageCropper: true,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.files.push(res.res.name);
            this.filesDropped.emit(this.files);
            this.filesDroppedEvent.emit([res.res]);
          }
        });
      }
    }

    if (!isDragEvent) {
      event.target.value = "";
    }
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }
}
