import { Component, Input, OnInit } from "@angular/core";
import { Payment } from "app/shared/models/payments/Payment";

@Component({
  selector: "payments-preview-table",
  templateUrl: "./payments-preview-table.component.html",
  styleUrls: ["./payments-preview-table.component.scss"],
})
export class PaymentsPreviewTableComponent implements OnInit {
  @Input() payments: Payment[];
  constructor() {}

  ngOnInit() {}

  getRowClass(row) {
    return {
      "new-entry": row.NewEntry,
    };
  }
}
