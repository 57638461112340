import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";

@Component({
  selector: "add-external-system-default",
  templateUrl: "./add-external-system-default.component.html",
  styleUrls: ["./add-external-system-default.component.scss"],
})
export class AddExternalSystemDefaultComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  openSupportPopover() {}
}
